import {
  format,
  subDays,
} from "date-fns";

const DEFAULT_DATE_FORMAT = "yyyy-MM-dd";
const TODAY = format(new Date(), DEFAULT_DATE_FORMAT);
const YESTERDAY = format(subDays(new Date(), 1), DEFAULT_DATE_FORMAT);

const ALL_TIME_VALUE = "alltime";
const ALL_TIME_DATE_RANGE = ["1900-01-01", "2030-01-01"];
const CUSTOM_TIME_VALUE = "custom";

const TIME_OPTION_IDS = {
  TODAY: "Today",
  YESTERDAY: "Yesterday",
  THIS_WEEK: "This week",
  THIS_MONTH: "This month",
  THIS_QUARTER: "This quarter",
  THIS_YEAR: "This year",

  LAST_7_DAYS: "Last 7 days",
  LAST_30_DAYS: "Last 30 days",
  LAST_6_MONTHS: "Last 6 months",
  LAST_WEEK: "Last week",
  LAST_MONTH: "Last month",
  LAST_QUARTER: "Last quarter",
  LAST_YEAR: "Last year",

  ALL_TIME: ALL_TIME_VALUE,
  DATE_RANGE: CUSTOM_TIME_VALUE
};

const TIME_OPTION_ID_VALUES = {};
const ids = Object.keys(TIME_OPTION_IDS);
ids.forEach(id => {
  TIME_OPTION_ID_VALUES[TIME_OPTION_IDS[id]] = id;
});

const LAST_PERIODS = {
  [TIME_OPTION_IDS.TODAY]: TIME_OPTION_IDS.YESTERDAY,
  [TIME_OPTION_IDS.THIS_WEEK]: TIME_OPTION_IDS.LAST_WEEK,
  [TIME_OPTION_IDS.THIS_MONTH]: TIME_OPTION_IDS.LAST_MONTH,
  [TIME_OPTION_IDS.THIS_QUARTER]: TIME_OPTION_IDS.LAST_QUARTER,
  [TIME_OPTION_IDS.THIS_YEAR]: TIME_OPTION_IDS.LAST_YEAR
};

const TIME_OPTIONS = [
  {
    label: TIME_OPTION_IDS.TODAY,
    id: TIME_OPTION_IDS.TODAY,
  },
  {
    label: TIME_OPTION_IDS.YESTERDAY,
    id: TIME_OPTION_IDS.YESTERDAY,
  },
  {
    label: TIME_OPTION_IDS.THIS_WEEK,
    id: TIME_OPTION_IDS.THIS_WEEK,
  },
  {
    label: TIME_OPTION_IDS.THIS_MONTH,
    id: TIME_OPTION_IDS.THIS_MONTH,
  },
  {
    label: TIME_OPTION_IDS.THIS_QUARTER,
    id: TIME_OPTION_IDS.THIS_QUARTER,
  },
  {
    label: TIME_OPTION_IDS.THIS_YEAR,
    id: TIME_OPTION_IDS.THIS_YEAR,
  },
  {
    label: TIME_OPTION_IDS.LAST_7_DAYS,
    id: TIME_OPTION_IDS.LAST_7_DAYS,
  },
  {
    label: TIME_OPTION_IDS.LAST_30_DAYS,
    id: TIME_OPTION_IDS.LAST_30_DAYS,
  },
  {
    label: TIME_OPTION_IDS.LAST_WEEK,
    id: TIME_OPTION_IDS.LAST_WEEK,
  },
  {
    label: TIME_OPTION_IDS.LAST_MONTH,
    id: TIME_OPTION_IDS.LAST_MONTH,
  },
  {
    label: TIME_OPTION_IDS.LAST_QUARTER,
    id: TIME_OPTION_IDS.LAST_QUARTER,
  },
  {
    label: TIME_OPTION_IDS.LAST_YEAR,
    id: TIME_OPTION_IDS.LAST_YEAR,
  },
  { label: "All time", id: TIME_OPTION_IDS.ALL_TIME },
  {
    label: "Custom date range",
    id: TIME_OPTION_IDS.DATE_RANGE,
  }
];

export {
  TODAY,
  ALL_TIME_VALUE,
  ALL_TIME_DATE_RANGE,
  TIME_OPTIONS,
  TIME_OPTION_IDS,
  CUSTOM_TIME_VALUE,
  TIME_OPTION_ID_VALUES,
  DEFAULT_DATE_FORMAT,
};
