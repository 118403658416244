import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { User } from 'src/types';

// Define a type for the slice state
interface UserState {
  value: User | undefined;
}

// Define the initial state using that type
const initialState: UserState = {
  value: undefined,
};

const userSlice = createSlice({
  name: 'user',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    setUser: (state, action: PayloadAction<any>) => {
      state.value = action.payload;
    },
  },
});

const { setUser } = userSlice.actions;
const userReducer = userSlice.reducer;

export { UserState, userReducer, setUser };
