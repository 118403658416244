import { html, css } from 'lit';
import { property } from 'lit/decorators.js';
import { NNBase } from '@mch/nn-web-viz/dist/packages/base/Base';
import { connect, store } from '../../../state/store';
import { hasura, hasuraQueries } from '../../../modules/hasura';

class TermsPopup extends connect(store)(NNBase) {
  @property({ type: Boolean, attribute: 'visible' }) visible = false;

  static styles = css`
    * {
      margin: 0;
      padding: 0;
      box-sizing: border-box;
      list-style: none;
      color: #fff;
    }

    .flex_align_justify {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .wrapper {
      min-height: 100vh;
      padding: 0 20px;
    }

    .tc_wrap {
      min-width: 700px;
      width: 50%;
      max-width: 100%;
      min-height: 450px;
      height: 60%;
      background: rgba(28, 31, 40, 0.28);
      box-shadow: 0px 24px 30px 0px rgba(0, 0, 0, 0.05);
      backdrop-filter: blur(18px);
      border-radius: 15px;
      display: flex;
      overflow: hidden;
    }

    .tc_wrap .tabs_list {
      width: 200px;
      background: rgba(28, 31, 40, 0.28);
      box-shadow: 0px 24px 30px 0px rgba(0, 0, 0, 0.05);
      height: 100%;
    }

    .tc_wrap .tabs_content {
      width: calc(100% - 200px);
      padding: 0 20px 0 20px;
      height: 100%;
    }

    .tc_wrap .tabs_content .tab_head,
    .tc_wrap .tabs_content .tab_foot {
      color: #f8029c;
      padding: 25px 0;
      height: 70px;
    }

    .tc_wrap .tabs_content .tab_head {
      text-align: center;
    }

    .tc_wrap .tabs_content .tab_body {
      height: calc(100% - 140px);
      overflow: auto;
    }

    .tc_wrap .tabs_list ul {
      padding: 70px 20px;
      text-align: right;
    }

    .tc_wrap .tabs_list ul li {
      padding: 10px 0;
      position: relative;
      margin-bottom: 3px;
      cursor: pointer;
      font-weight: bold;
      transition: all 0.5s ease;
    }

    .tc_wrap .tabs_list ul li:before {
      content: '';
      position: absolute;
      top: 0;
      right: -20px;
      width: 2px;
      height: 100%;
      background: #f8029c;
      opacity: 0;
      transition: all 0.5s ease;
    }

    .tc_wrap .tabs_list ul li.active,
    .tc_wrap .tabs_list ul li:hover {
      color: #f8029c;
    }
    p {
      color: #fff;
    }

    .tc_wrap .tabs_list ul li.active:before {
      opacity: 1;
    }

    .tc_wrap .tabs_content .tab_body .tab_item h3 {
      padding-top: 10px;
      margin-bottom: 10px;
      color: #f8029c;
    }

    .tc_wrap .tabs_content .tab_body .tab_item p {
      margin-bottom: 20px;
    }

    .tc_wrap .tabs_content .tab_body .tab_item.active {
      display: block !important;
    }

    .tc_wrap .tabs_content .tab_foot button {
      width: 125px;
      padding: 5px 10px;
      background: transparent;
      border: 1px solid;
      border-radius: 25px;
      cursor: pointer;
      transition: all 0.5s ease;
    }

    .tc_wrap .tabs_content .tab_foot button.decline {
      margin-right: 15px;
      border-color: #fff;
      color: #fff;
    }

    .tc_wrap .tabs_content .tab_foot button.agree {
      background: #f8029c;
      border-color: #f8029c;
      color: #ffffff;
    }

    .tc_wrap .tabs_content .tab_foot button.decline:hover {
      background: rgba(28, 31, 40, 0.48);
      box-shadow: 0px 24px 30px 0px rgba(0, 0, 0, 0.05);
      color: #ffffff;
    }

    .tc_wrap .tabs_content .tab_foot button.agree:hover {
      background: #6c00ff;
      border-color: #6c00ff;
    }

    .overlay {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(28, 31, 40, 0.28);
      box-shadow: 0px 24px 30px 0px rgba(0, 0, 0, 0.05);
      display: flex;
      justify-content: center;
      align-items: center;
      visibility: hidden;
      opacity: 0;
      transition: opacity 0.3s ease, visibility 0.3s ease;
      z-index: 999;
    }

    .overlay.visible {
      visibility: visible;
      opacity: 1;
    }

    .modal {
      background: #fff;
      padding: 20px;
      border-radius: 8px;
      box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    }

    .buttons {
      display: flex;
      justify-content: flex-end;
      gap: 10px;
    }
  `;

  render() {
    return html`
      <div class="overlay ${this.visible ? 'visible' : ''}">
        <div class="tc_wrap">
          <div class="tabs_list">
            <ul>
              <li data-tc="tab_item_1">Terms of use</li>
              <li data-tc="tab_item_2">Registration and access</li>
              <li data-tc="tab_item_3">Using our Platforms</li>
              <li data-tc="tab_item_4">Content and IP rights</li>
              <li data-tc="tab_item_5">Disclaimer of warranties</li>
              <li data-tc="tab_item_6">Limitation of liability</li>
              <li data-tc="tab_item_7">Indemnity</li>
              <li data-tc="tab_item_8">Dispute resolution and arbitration</li>
              <li data-tc="tab_item_9">General terms</li>
            </ul>
          </div>
          <div class="tabs_content">
            <div class="tab_head">
              <h2>Terms & Conditions</h2>
            </div>
            <div class="tab_body">
              <div class="tab_item tab_item_1">
                <h3>Terms of use</h3>
                <p>Effective: July, 17, 2024</p>
                <p>
                  Thank you for using 90NORTH! These Terms of Use apply to your
                  use of ADELe, Living Journeys, Living Personas, and 90NORTH's
                  other services for clients or partners, along with any
                  associated software applications (all together, “Platforms”).
                  These Terms form an agreement between you and 90NORTH, an IPG
                  Health company, and they include our Service Terms and
                  important provisions for resolving disputes through
                  arbitration. By using our Platforms, you agree to these Terms.
                </p>
                <p>
                  If you reside in the European Economic Area, Switzerland, or
                  the UK, your use of the Services is governed by these terms.
                </p>
                <p>
                  Our Business Terms govern use of ADELe, Living Journeys,
                  Living Personas, our APIs, and our other services for
                  businesses and developers.
                </p>
                <p>
                  Our Privacy Policy explains how we collect and use personal
                  information. Although it does not form part of these Terms, it
                  is an important document that you should read.
                </p>
                <h3>Who we are:</h3>
                <p>
                  IPG Health (DBA 90NORTH) is an AI research and deployment
                  company. Our mission is to become the data-driven, AI-powered
                  operating system for creative consulting and execution at IPG
                  Health. For more information about IPG Health (DBA 90NORTH),
                  please visit
                  <a href="https://90NORTH.com/about"
                    >https://90NORTH.com/about</a
                  >.
                </p>
              </div>
              <div class="tab_item tab_item_2" style="display: none;">
                <h3>Registration and access</h3>
                <p>
                  Registration. You will be given access to our Platforms once a
                  service agreement has been completed by all parties. You will
                  receive access instructions by the Account lead. You may not
                  share your account credentials or make your account available
                  to anyone else and are responsible for all activities that
                  occur under your account. If you create an account or use the
                  Platforms on behalf of another person or entity, you must have
                  the authority to accept these Terms on their behalf.
                </p>
              </div>
              <div class="tab_item tab_item_3" style="display: none;">
                <h3>Using our Platforms</h3>
                <p>
                  What you can do. Subject to your compliance with these Terms,
                  you may access and use our Platforms. In using our Platforms,
                  you must comply with all applicable laws as well as our
                  Sharing & Publication Policy, Usage Policies, and any other
                  documentation, guidelines, or policies we make available to
                  you.
                </p>
                <p>
                  What you cannot do. You may not use our Platforms for any
                  illegal, harmful, or abusive activity. For example, you may
                  not:
                </p>
                <ul>
                  <li>
                    Use our Platforms in a way that infringes, misappropriates,
                    or violates anyone's rights.
                  </li>
                  <li>
                    Modify, copy, lease, sell or distribute any of our
                    Platforms.
                  </li>
                  <li>
                    Attempt to or assist anyone to reverse engineer, decompile
                    or discover the source code or underlying components of our
                    Platforms, including our models, algorithms, or systems
                    (except to the extent this restriction is prohibited by
                    applicable law).
                  </li>
                  <li>
                    Automatically or programmatically extract data or Output
                    (defined below).
                  </li>
                  <li>
                    Represent that Output was human-generated when it was not.
                  </li>
                  <li>
                    Interfere with or disrupt our Platforms, including
                    circumvent any restrictions or bypass any protective
                    measures or safety mitigations we put on our Platforms.
                  </li>
                  <li>
                    Use Output to develop models that compete with IPG Health
                    (DBA 90NORTH).
                  </li>
                </ul>

                <p>
                  Software. Our software may include open source software that
                  is governed by its own licenses that we've made available to
                  you.
                </p>
                <p>
                  Corporate domains. If you create an account using an email
                  address owned by an organization (for example, IPG Health or
                  your employer), that account may be added to the
                  organization's business account with us, in which case we will
                  provide notice to you so that you can help facilitate the
                  transfer of your account (unless your organization has already
                  provided notice to you that it may monitor and control your
                  account). Once your account is transferred, the organization's
                  administrator will be able to control your account, including
                  being able to access Content (defined below) and restrict or
                  remove your access to the account.
                </p>
                <p>
                  Third party Services. Our Platforms may include third party
                  software, products, or services, (“Third Party Services”) and
                  some parts of our Platforms, like our browse feature, may
                  include output from those services (“Third Party Output”).
                  Third Party Services and Third-Party Output are subject to
                  their own terms, and we are not responsible for them.
                </p>
                <p>
                  Feedback. We appreciate your feedback, and you agree that we
                  may use it without restriction or compensation to you.
                </p>
              </div>
              <div class="tab_item tab_item_4" style="display: none;">
                <h3>Content</h3>
                <p>
                  Your content. You may provide input to the Platforms (“Input”)
                  and receive output from the Platforms based on the Input
                  (“Output”). Input and Output are collectively “Content.” You
                  are responsible for Content, including ensuring that it does
                  not violate any applicable law or these Terms. You represent
                  and warrant that you have all rights, licenses, and
                  permissions needed to provide Input to our Platforms.
                </p>
                <p>
                  Ownership of content. As between you and IPG Health (DBA
                  90NORTH), and to the extent permitted by applicable law, you
                  (a) retain your ownership rights in Input and (b) own the
                  Output. We hereby assign to you all our right, title, and
                  interest, if any, in and to Output.
                </p>
                <p>
                  Similarity of content. Due to the nature of our Platforms and
                  artificial intelligence generally, output may not be unique
                  and other users may receive similar output from our Platforms.
                  Our assignment above does not extend to other users' output or
                  any Third-Party Output.
                </p>
                <p>
                  Our use of content. We may use Content to provide, maintain,
                  develop, and improve our Platforms, comply with applicable
                  law, enforce our terms and policies, and keep our Platforms
                  safe.
                </p>
                <p>
                  Accuracy. Artificial intelligence and machine learning are
                  rapidly evolving fields of study. We are constantly working to
                  improve our Platforms to make them more accurate, reliable,
                  safe, and beneficial. Given the probabilistic nature of
                  machine learning, use of our Platforms may, in some
                  situations, result in Output that does not accurately reflect
                  real people, places, or facts.
                </p>
                <p>When you use our Platforms, you understand and agree:</p>
                <ul>
                  <li>
                    Output may not always be accurate. You should not rely on
                    Output from our Platforms as a sole source of truth or
                    factual information, or as a substitute for professional
                    advice.
                  </li>
                  <li>
                    You must evaluate Output for accuracy and appropriateness
                    for your use case, including using human review as
                    appropriate, before using or sharing Output from the
                    Platforms.
                  </li>
                  <li>
                    You must not use any Output relating to a person for any
                    purpose that could have a legal or material impact on that
                    person, such as making credit, educational, employment,
                    housing, insurance, legal, medical, or other important
                    decisions about them.
                  </li>
                </ul>
              </div>
              <div class="tab_item tab_item_5" style="display: none;">
                <h3>Disclaimer of warranties</h3>
                <p>
                  OUR PLATFORMS ARE PROVIDED “AS IS.” EXCEPT TO THE EXTENT
                  PROHIBITED BY LAW, WE AND OUR AFFILIATES AND LICENSORS MAKE NO
                  WARRANTIES (EXPRESS, IMPLIED, STATUTORY OR OTHERWISE) WITH
                  RESPECT TO THE PLATFORMS, AND DISCLAIM ALL WARRANTIES
                  INCLUDING, BUT NOT LIMITED TO, WARRANTIES OF MERCHANTABILITY,
                  FITNESS FOR A PARTICULAR PURPOSE, SATISFACTORY QUALITY,
                  NON-INFRINGEMENT, AND QUIET ENJOYMENT, AND ANY WARRANTIES
                  ARISING OUT OF ANY COURSE OF DEALING OR TRADE USAGE. WE DO NOT
                  WARRANT THAT THE PLATFORMS WILL BE UNINTERRUPTED, ACCURATE OR
                  ERROR FREE, OR THAT ANY CONTENT WILL BE SECURE OR NOT LOST OR
                  ALTERED.
                </p>
                <p>
                  YOU ACCEPT AND AGREE THAT ANY USE OF OUTPUTS FROM OUR SERVICE
                  IS AT YOUR SOLE RISK AND YOU WILL NOT RELY ON OUTPUT AS A SOLE
                  SOURCE OF TRUTH OR FACTUAL INFORMATION, OR AS A SUBSTITUTE FOR
                  PROFESSIONAL ADVICE.
                </p>
              </div>
              <div class="tab_item tab_item_6" style="display: none;">
                <h3>Limitation of liability</h3>
                <p>
                  NEITHER WE NOR ANY OF OUR AFFILIATES OR LICENSORS WILL BE
                  LIABLE FOR ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL,
                  OR EXEMPLARY DAMAGES, INCLUDING DAMAGES FOR LOSS OF PROFITS,
                  GOODWILL, USE, OR DATA OR OTHER LOSSES, EVEN IF WE HAVE BEEN
                  ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. OUR AGGREGATE
                  LIABILITY UNDER THESE TERMS WILL NOT EXCEED THE GREATER OF THE
                  AMOUNT YOU PAID FOR THE SERVICE THAT GAVE RISE TO THE CLAIM
                  DURING THE 12 MONTHS BEFORE THE LIABILITY AROSE OR ONE HUNDRED
                  DOLLARS ($100). THE LIMITATIONS IN THIS SECTION APPLY ONLY TO
                  THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW.
                </p>
                <p>
                  Some countries and states do not allow the disclaimer of
                  certain warranties or the limitation of certain damages, so
                  some or all the terms above may not apply to you, and you may
                  have additional rights. In that case, these Terms only limit
                  our responsibilities to the maximum extent permissible in your
                  country of residence.
                </p>
                <p>
                  IPG Health (DBA 90NORTH)'s AFFILIATES, SUPPLIERS, LICENSORS,
                  AND DISTRIBUTORS ARE INTENDED THIRD PARTY BENEFICIARIES OF
                  THIS SECTION.
                </p>
              </div>
              <div class="tab_item tab_item_7" style="display: none;">
                <h3>Indemnity</h3>
                <p>
                  If you are a business or organization, to the extent permitted
                  by law, you will indemnify and hold harmless us, our
                  affiliates, and our personnel, from and against any costs,
                  losses, liabilities, and expenses (including attorneys' fees)
                  from third party claims arising out of or relating to your use
                  of the Platforms and Content or any violation of these Terms.
                </p>
              </div>
              <div class="tab_item tab_item_8" style="display: none;">
                <h3>Dispute resolution and arbitration</h3>
                <p>
                  YOU AND IPG Health (DBA 90NORTH)AGREE TO THE FOLLOWING
                  MANDATORY ARBITRATION AND CLASS ACTION WAIVER PROVISIONS:
                </p>
                <ul>
                  <li>
                    MANDATORY ARBITRATION. You and IPG Health (DBA 90NORTH)agree
                    to resolve any claims arising out of or relating to these
                    Terms or our Services, regardless of when the claim arose,
                    even if it was before these Terms existed (a “Dispute”),
                    through final and binding arbitration. You may opt out of
                    arbitration within 30 days of account creation or of any
                    updates to these arbitration terms within 30 days after the
                    update has taken effect by emailing us at
                    help@90north.health. If you opt out of an update, the last
                    set of agreed upon arbitration terms will apply.
                  </li>
                  <li>
                    Informal dispute resolution. We would like to understand and
                    try to address your concerns prior to formal legal action.
                    Before either of us files a claim against the other, we both
                    agree to try to resolve the Dispute informally. You agree to
                    do so by sending us notice through this email
                    (help@90north.health). We will do so by sending you notice
                    to the email address associated with your account. If we are
                    unable to resolve a Dispute within 60 days, either of us has
                    the right to initiate arbitration. We also both agree to
                    attend an individual settlement conference if either party
                    requests one during this time. Any statute of limitations
                    will be tolled during this informal resolution process.
                  </li>
                  <li>
                    Arbitration forum. If we are unable to resolve the Dispute,
                    either of us may commence arbitration with National
                    Arbitration and Mediation (“NAM”) under its Comprehensive
                    Dispute Resolution Rules and Procedures and/or Supplemental
                    Rules for Mass Arbitration Filings, as applicable. IPG
                    Health (DBA 90NORTH)will not seek attorneys' fees and costs
                    in arbitration unless the arbitrator determines that your
                    claim is frivolous. The activities described in these Terms
                    involve interstate commerce and the Federal Arbitration Act
                    will govern the interpretation and enforcement of these
                    arbitration terms and any arbitration.
                  </li>
                  <li>
                    Arbitration procedures. The arbitration will be conducted by
                    video conference, if possible, but if the arbitrator
                    determines a hearing should be conducted in person, the
                    location will be mutually agreed upon, in the county where
                    you reside, or as determined by the arbitrator, unless the
                    batch arbitration process applies. The arbitration will be
                    conducted by a sole arbitrator. The arbitrator will be
                    either a retired judge or an attorney licensed to practice
                    law in the state of California. The arbitrator will have
                    exclusive authority to resolve any Dispute, except the state
                    or federal courts of San Francisco, California have the
                    authority to determine any Dispute about enforceability,
                    validity of the class action waiver, or requests for public
                    injunctive relief, as set out below. Any settlement offer
                    amounts will not be disclosed to the arbitrator by either
                    party until after the arbitrator determines the final award,
                    if any. The arbitrator has the authority to grant motions
                    dispositive of all or part of any Dispute.
                  </li>
                </ul>

                <p>
                  Exceptions. This section does not require informal dispute
                  resolution or arbitration of the following claims:
                </p>
                <ul>
                  <li>Individual claims brought in small claims court.</li>
                  <li>
                    Injunctive or other equitable relief to stop unauthorized
                    use or abuse of the Platforms or intellectual property
                    infringement or misappropriation.
                  </li>
                </ul>

                <p>
                  CLASS AND JURY TRIAL WAIVERS. You and IPG Health (DBA
                  90NORTH)agree that Disputes must be brought on an individual
                  basis only and may not be brought as a plaintiff or class
                  member in any purported class, consolidated, or representative
                  proceeding. Class arbitrations, class actions, and
                  representative actions are prohibited. Only individual relief
                  is available. The parties agree to sever and litigate in court
                  any request for public injunctive relief after completing
                  arbitration for the underlying claim and all other claims.
                  This does not prevent either party from participating in a
                  class-wide settlement. You and IPG Health (DBA 90NORTH)
                  knowingly and irrevocably waive any right to trial by jury in
                  any action, proceeding, or counterclaim.
                </p>
                <p>
                  Batch arbitration. If 25 or more claimants represented by the
                  same or similar counsel file demands for arbitration raising
                  substantially similar Disputes within 90 days of each other,
                  then you and IPG Health (DBA 90NORTH) agree that NAM will
                  administer them in batches of up to 50 claimants each
                  (“Batch”), unless there are less than 50 claimants in total or
                  after batching, which will comprise a single Batch. NAM will
                  administer each Batch as a single consolidated arbitration
                  with one arbitrator, one set of arbitration fees, and one
                  hearing held by videoconference or in a location decided by
                  the arbitrator for each Batch. If any part of this section is
                  found to be invalid or unenforceable as to a particular
                  claimant or Batch, it will be severed and arbitrated in
                  individual proceedings.
                </p>
                <p>
                  Severability. If any part of these arbitration terms is found
                  to be illegal or unenforceable, the remainder will remain in
                  effect, except that if a finding of partial illegality or
                  unenforceability would allow class arbitration, class action,
                  or representative action, this entire dispute resolution
                  section will be unenforceable in its entirety.
                </p>
              </div>
              <div class="tab_item tab_item_9" style="display: none;">
                <h3>General terms</h3>
                <p>
                  Assignment. You may not assign or transfer any rights or
                  obligations under these Terms and any attempt to do so will be
                  void. We may assign our rights or obligations under these
                  Terms to any affiliate, subsidiary, or successor in interest
                  of any business associated with our Platforms.
                </p>
                <p>
                  Changes to these Terms or our Services. We are continuously
                  working to develop and improve our Platforms. We may update
                  these Terms or our Services accordingly from time to time. For
                  example, we may make changes to these Terms or the Services
                  due to:
                </p>
                <ul>
                  <li>Changes to the law or regulatory requirements.</li>
                  <li>Security or safety reasons.</li>
                  <li>Circumstances beyond our reasonable control.</li>
                  <li>
                    Changes we make in the usual course of developing our
                    Platforms.
                  </li>
                  <li>To adapt to new technologies.</li>
                </ul>

                <p>
                  We will give you at least 30 days advance notice of changes to
                  these Terms that materially adversely impact you either via
                  email or an in-product notification. All other changes will be
                  effective as soon as we post them to our website. If you do
                  not agree to the changes, you must stop using our Platforms.
                </p>
                <p>
                  Delay in enforcing these Terms. Our failure to enforce a
                  provision is not a waiver of our right to do so later. Except
                  as provided in the dispute resolution section above, if any
                  portion of these Terms is determined to be invalid or
                  unenforceable, that portion will be enforced to the maximum
                  extent permissible, and it will not affect the enforceability
                  of any other terms.
                </p>
                <p>
                  Trade controls. You must comply with all applicable trade
                  laws, including sanctions and export control laws. Our
                  Platforms may not be used in or for the benefit of, or
                  exported or re-exported to (a) any U.S. embargoed country or
                  territory or (b) any individual or entity with whom dealings
                  are prohibited or restricted under applicable trade laws. Our
                  Platforms may not be used for any end use prohibited by
                  applicable trade laws, and your Input may not include material
                  or information that requires a government license for release
                  or export.
                </p>
                <p>
                  Entire agreement. These Terms contain the entire agreement
                  between you and IPG Health (DBA 90NORTH) regarding the
                  Platforms and, other than any Service-specific terms,
                  supersedes any prior or contemporaneous agreements between you
                  and IPG Health (DBA 90NORTH).
                </p>
                <p>
                  Governing law. California law will govern these Terms except
                  for its conflicts of laws principles. Except as provided in
                  the dispute resolution section above, all claims arising out
                  of or relating to these Terms will be brought exclusively in
                  the federal or state courts of San Francisco, California.
                </p>
              </div>
            </div>
            <div class="tab_foot flex_align_justify">
              <button class="decline" @click="${this._declineTerms}">
                Decline
              </button>
              <button class="agree" @click="${this._acceptTerms}">Agree</button>
            </div>
          </div>
        </div>
      </div>
    `;
  }

  firstUpdated() {
    const tabLists =
      this.shadowRoot?.querySelectorAll<HTMLLIElement>('.tabs_list ul li');
    const tabItems =
      this.shadowRoot?.querySelectorAll<HTMLDivElement>('.tab_item');

    if (tabLists && tabItems) {
      tabLists.forEach(list => {
        list.addEventListener('click', () => {
          const tabData = list.getAttribute('data-tc');

          tabLists.forEach(list => {
            list.classList.remove('active');
          });
          list.classList.add('active');

          tabItems.forEach(item => {
            if (tabData && item.classList.contains(tabData)) {
              item.style.display = 'block';
            } else {
              item.style.display = 'none';
            }
          });
        });
      });

      // Ensure the first tab and its content are active initially
      if (tabLists.length > 0 && tabItems.length > 0) {
        tabLists[0].classList.add('active');
        tabItems[0].style.display = 'block';
      }
    }
  }

  async _updateTerms() {
    const userId = store.getState().appConfig.value?.userId;
    if (userId == null) return;

    await hasura.query(
      hasuraQueries.updateTerms({
        id: parseInt(userId, 10),
        agreedToTerms: true,
      })
    );
  }

  private _acceptTerms() {
    this._updateTerms();

    this.visible = false;
    this.dispatchEvent(new CustomEvent('accepted'));
  }

  private _declineTerms() {
    alert('You need to accept the terms to use this site.');
  }
}

export { TermsPopup };
