import { css } from 'lit';

const EEProjectCreationFormStyles = [
  css`
    :host {
      font-family: 'Montserrat', sans-serif;
      background-color: #121212;
      color: #e0e0e090;
      margin: 0;
      padding: 0;
      --nn-primary-text-color: white;
      --lumo-body-text-color: white;
      --vaadin-input-field-label-color: white;
    }

    .container {
      width: 80%;
      margin: 100px auto; /* Increased margin for more space */
      padding: 20px;
      text-align: center;
    }

    span {
      font-size: 1.2em;
      font-weight: 400;
    }

    h1,
    p {
      font-size: 1.2em;
      margin-bottom: 30px; /* Increased margin for more space */
      font-weight: 400;
    }

    p {
      display: none;
    }

    input[type='text'],
    select {
      font-family: 'Montserrat', sans-serif;
      font-size: 1em;
      color: #e0e0e0;
      background-color: transparent;
      border: none;
      border-bottom: 2px solid #383838; /* Subtle border */
      margin: 0 10px; /* Adjusted margin */
      display: inline-block;
      padding: 0px;
      text-align: center;
      width: auto;
    }

    input[type='text']:focus,
    select:focus {
      outline: none;
      border-bottom: 2px solid #e0e0e0; /* Focus effect */
    }

    select {
      width: 200px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    button {
      background-color: #f8029c; /* Simplified button color */
      color: white;
      padding: 15px 30px;
      margin: 30px auto; /* Increased margin */
      border: none;
      border-radius: 50px;
      cursor: pointer;
      transition: all 0.3s ease; /* Smooth transition for all effects */
      font-weight: 500;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
      opacity: 0; /* Initially hidden, like the sentences */
      transform: translateY(20px);
      transition: opacity 2s ease-out, transform 2s ease-out;
    }

    button.show {
      opacity: 1;
      transform: translateY(0);
    }

    button:hover {
      background-color: #f8029c50; /* Hover effect */
      transform: scale(1.05); /* Slight scale */
    }

    /* Additional CSS for animations and gradient text */
    p {
      opacity: 0;
      transform: translateY(20px);
      transition: opacity 2s ease-out, transform 2s ease-out; /* Increased duration and changed easing */
    }

    p.show {
      opacity: 1;
      transform: translateY(0);
    }

    /* Grey gradient text */
    h1,
    p {
      background: -webkit-linear-gradient(top, #878787, #bdbdbd);
      background-clip: text;
      -webkit-background-clip: text;
    }

    #formIndicator {
      color: #aaa; /* Light gray color */
      font-style: italic;
      display: var(--form-indicator-display, block); /* Initially hidden */
      opacity: 1;
      margin-top: 100px;
    }

    #secondLine {
      display: var(--second-line-display, none);
      transition: opacity 2s ease-out, transform 2s ease-out; /* Increased duration and changed easing */
    }

    #thirdLine {
      display: var(--third-line-display, none);
      transition: opacity 2s ease-out, transform 2s ease-out; /* Increased duration and changed easing */
    }

    input:-webkit-autofill,
    input:-webkit-autofill:focus {
      transition: background-color 0s 600000s, color 0s 600000s !important;
    }
  `,
];

export { EEProjectCreationFormStyles };
