import {
  MacroThemeQueryParams,
  ProjectParams,
  RecommendationParams,
  VerbatimParams,
  ProjectIdParams,
  WidgetParams,
  HasuraQueries,
  JourneyStepParams,
  ProjectFieldParams,
  ClientAccountParams,
  TaAccountParams,
  WTDParams,
  WTDMacroThemeParams,
  BrandSlangParams,
  BrandParams,
} from './types';

const getProjects = ({ projectId }: ProjectParams) => `
  {
    project(where: {id: {_eq: "${projectId}"}}) {
      id
    project_brands(where: {project_main_brand: {_eq: true}}) {
      brand {
        id
        name
      }
    }
  }
}
`;

const getVerbatims = ({ subThemeId }: VerbatimParams) => `
  {
    verbatim(where: {sub_theme_verbatims: {sub_theme_id: {_eq: ${subThemeId}}}}) {
      id
      text
      emotion_spans {
        span_end
        span_start
        secondary_emotion_emotion_spans {
          secondary_emotion {
            name
            valance_score
          }
        }
      }
    }
  }
`;

const updateSubTheme = ({ description, subThemeId }: VerbatimParams) => `
  mutation UpdateSubTheme {
    update_sub_theme(where: {id: {_eq: ${subThemeId}}}, _set: {description: "${description}"}) {
      affected_rows
      returning {
        description
      }
    }
  }
`;

const getRecommendation = ({ id }: RecommendationParams) => `{
  recommendation(where: {id: {_eq: ${id}}}) {
    accepted
    dismissed
    recommendation
    title
    type
    priority_level
    feasibility_level
    cx_impact
    business_impact
    id
  }
}
`;

const getRecommendations = ({
  projectId,
  macroThemeId,
}: MacroThemeQueryParams) => `
{
  recommendation(where: {project_id: {_eq: "${projectId}"}, recommendation_macro_themes: {macro_theme_id: {_eq: ${macroThemeId}}}}, order_by: {order: asc, created: asc}) {
    id
    type
    title
    accepted
    created
    priority_level
    order
    recommendation
  }
}

`;

const dismissRecommendation = ({ id }: RecommendationParams) => `
  mutation DismissRecommendation {
    update_recommendation(where: {id: {_eq: ${id}}}, _set: {accepted: false, dismissed: true}) {
      affected_rows
    }
  }
`;

const acceptRecommendation = ({ id }: RecommendationParams) => `
  mutation AcceptRecommendation {
    update_recommendation(where: {id: {_eq: ${id}}}, _set: {accepted: true, dismissed: false }) {
      affected_rows
    }
  }
`;

const getUserSettings = () => `{
  user_settings {
    settings
   }
  }
`;

const createUpdateUserSettings = () => `
  mutation CreateUpdateUserSettingsMutation($objects: [user_settings_insert_input!] = {}) {
    insert_user_settings(objects: $objects, on_conflict: {constraint: user_settings_pkey, update_columns: settings}) {
      affected_rows
    }
  }
`;

const deleteUserSettings = () => `
  mutation DeleteUserSettingsMutation {
    delete_user_settings(where: {}) {
      affected_rows
    }
  }
`;

const getActiveDashboardWidgetRecommendation = ({ id }) => `
  {
    dashboard_widget_recommendation(where: {recommendation_id: {_eq: ${id}}, active: {_eq: true}}) {
      dashboard_widget {
        html_element_tag
        id
        props
      }
    }
  }
`;

const getAllDashboardWidgetRecommendation = ({ id }) => `
  {
    dashboard_widget_recommendation(where: {recommendation_id: {_eq: ${id}}}) {
      dashboard_widget {
        html_element_tag
        id
        props
      }
    }
  }
`;

const getKPIs = ({ projectId }: ProjectIdParams) => `
  {
    kpi(where: {project_id: {_eq: ${projectId}}}) {
      id
      description
      title
    }
  }
`;

const addDashboardWidget = () => `
  mutation insert_dashboard_widget($object: dashboard_widget_insert_input!) {
    insert_dashboard_widget(objects: [$object]) {
      returning {
        id
        project_id
        props
        html_element_tag
      }
    }
  }
`;

const updateDashboardWidget = (id: number) => `
  mutation UpdateWidgetMutation($objects: dashboard_widget_set_input!) {
    update_dashboard_widget(where: {id: {_eq: ${id}}}, _set: $objects) {
      returning {
        html_element_tag
        id
        project_id
        props
      }
    }
  }
`;

const getAllDashboardWidget = () => `
  query retrieve_dashboard_widget {
    dashboard_widget {
      html_element_tag
      id
      project_id
      props
    }
  }
`;

const addDashboardWidgetRecommendation = (params: WidgetParams) => `
  mutation AssignWidgetMutation {
    insert_dashboard_widget_recommendation_one(object: {dashboard_widget_id: ${params.widgetId}, recommendation_id: ${params.recommendationId}}) {
      id
    }
  }
`;

const deleteDashboardWidgetRecommendation = (params: WidgetParams) => `
  mutation RemoveWidgetMutation {
    delete_dashboard_widget_recommendation(where: {dashboard_widget_id: {_eq: ${params.widgetId}}, recommendation_id: {_eq: ${params.recommendationId}}}) {
      affected_rows
    }
  }
`;

const getStrategicImperatives = ({ projectId }: ProjectIdParams) => `
 {
    strategic_imperative(where: {project_id: {_eq: ${projectId}}}) {
      description
      id
    }
 }
`;

const activateWidgetRecommendation = (params: WidgetParams) => `
  mutation ActivateWidgetMutation {
    update_dashboard_widget_recommendation(where: {recommendation_id: {_eq: ${params.recommendationId}}, dashboard_widget_id: {_eq: ${params.widgetId}}}, _set: {active: true}) {
      affected_rows
    }
  }
`;

const deactivateWidgetRecommendation = (params: WidgetParams) => `
  mutation DeactivateWidgetMutation {
    update_dashboard_widget_recommendation(where: {recommendation_id: {_eq: ${params.recommendationId}}, dashboard_widget_id: {_eq: ${params.widgetId}}}, _set: {active: false}) {
      affected_rows
    }
  }
`;

const getProjectStakeholders = ({ projectId }: ProjectIdParams) => `
  query listStakeholdersProject {
    stakeholder(where: {verbatims: {project_id: {_eq: ${projectId}}}}) {
      id
      name
      image_url
    }
  }
`;

const getRecommendationStakeholders = ({ id }: RecommendationParams) => `
  query listStakeholdersRecommendation {
    recommendation(where: {id: {_eq: ${id}}}) {
      recommendation_sub_themes {
        sub_theme {
          sub_theme_verbatims {
            verbatim {
              stakeholder {
                id
                image_url
                name
              }
            }
          }
        }
      }
    }
  }
`;

const getProjectJourneySteps = ({ projectId }: ProjectIdParams) => `
  query ListJourneyStepsOfProject {
    recommendation(where: {project_id: {_eq: ${projectId}}}) {
      id
      recommendation_sub_themes {
        sub_theme {
          sub_theme_verbatims {
            verbatim {
              verbatim_journey_steps {
                journey_step {
                  name
                  id
                }
              }
            }
          }
        }
      }
    }
  }
`;

const getRecommendationJourneySteps = ({ id }: RecommendationParams) => `
  query ListJourneyStepsOfRecommendation {
    recommendation(where: {id: {_eq: ${id}}}) {
      id
        recommendation_sub_themes {
          sub_theme {
            sub_theme_verbatims {
              verbatim {
                verbatim_journey_steps {
                  journey_step {
                    name
                    id
                  }
              }
            }
          }
        }
      }
    }
  }
`;

const updateProjectJourneyStep = ({ id, name }: JourneyStepParams) => `
  mutation UpdateProjectJourneyStepMutation {
    update_journey_step(where: {id: {_eq: ${id}}}, _set: {name: "${name}"}) {
      returning {
        id
        name
      }
    }
  }
`;

const getProjectList = () => `
  query Projects {
    project(where: {in_trash: {_eq: false}}) {
      id
      name
      created
      client {
        image_url
      }
    }
  }
`;

const softDeleteProject = () => `
  mutation SoftDeleteProjectMutation($_eq: String = "") {
    update_project(where: {id: {_eq: $_eq}}, _set: {in_trash: true}) {
      affected_rows
    }
  }
`;

const getProjectFields = () => `
  query MyQuery {
    ta {
      id
      name
    }
    client {
      id
      name
    }
    product(where: {suitable_for_project_creation: {_eq: true}}) {
      id
      name
    }
    account {
      id
      name
    }
  }
`;

const createClient = (client: string) => `
  mutation MyMutation {
    insert_client(objects: {name: "${client}"}) {
      returning {
        id
        name
      }
    }
  }
`;

const createClientAccount = ({ accountId, clientId }: ClientAccountParams) => `
  mutation MyMutation {
    insert_client_account(objects: {client_id: ${clientId}, account_id: ${accountId}}, on_conflict: {constraint: client_account_pkey}) {
      affected_rows
    }
  }
`;

const createTaAccount = ({ accountId, taId }: TaAccountParams) => `
  mutation MyMutation {
    insert_ta_account(objects: {ta_id: ${taId}, account_id: ${accountId}}, on_conflict: {constraint: ta_account_pkey}) {
      affected_rows
    }
  }
`;

const createTa = (ta: string) => `
  mutation MyMutation {
    insert_ta(objects: {name: "${ta}"}) {
      returning {
        id
        name
      }
    }
  }
`;

const getProducts = ({ isSuitableForProjectCreation }) => `
  query GetProducts {
    product(where: {suitable_for_project_creation: {_eq: ${isSuitableForProjectCreation}}}) {
      id
      is_external_path
      name
      path
      suitable_for_project_creation
    }
  }
`;

const createProject = ({
  projectName,
  clientId,
  productId,
  taId,
  accountId,
  id,
}: ProjectFieldParams) => `
  mutation MyMutation($project_form_data: json = {}) {
    insert_account_project(
      objects: {
        account_id: ${accountId},
        project: {
          data: {
            name: ${projectName},
            id: "${id}",
            client_id: ${clientId},
            product_id: ${productId},
            ta_id: ${taId},
            project_form_data: $project_form_data
          }
        }
      }) {
      returning {
        account_id
        project_id
      }
    }
  }
`;

const getThemes = ({ projectId }: ProjectParams) => `
  query ThemesQuery {
    sub_theme(where: {macro_theme: {project_id: {_eq: "${projectId}"}}}) {
      id
      label
      description
      display_label
    }
    macro_theme(where: {project_id: {_eq: "${projectId}"}}) {
      id
      label
      description
      display_label
    }
  }
`;

const getProjectSettings = ({ projectId }: ProjectParams) => `
{
  project_settings(where: {project_id: {_eq: "${projectId}"}}) {
    project_id
    settings
    views
  }
}
`;

const insertProjectSettings = () => `
  mutation InsertProjectSettingsMutation($objects: [project_settings_insert_input!] = {}, $update_columns: [project_settings_update_column!] = [settings, views]) {
    insert_project_settings(objects: $objects, on_conflict: {constraint: project_settings_pkey, update_columns: $update_columns}) {
      returning {
        project_id
        settings
        views
      }
    }
  }
 `;

const updateProjectSettings = ({ projectId }: ProjectParams) => `
  mutation MyMutation($settings: json = "", $views: json = "") {
    update_project_settings(where: {project_id: {_eq: "${projectId}"}}, _set: {settings: $settings, views: $views}) {
      returning {
        project_id
        id
        settings
        views
      }
    }
  }
`;

const getDepartments = () => `
  query GetDepartmentsQuery {
    department {
      id
      name
    }
  }
`;

const insertRecommendation = ({
  projectId,
  recommendationType,
  description,
  title,
  priorityLevel,
}: WTDParams) => `
  mutation MyMutation {
    insert_recommendation(objects: {
      accepted: false,
      business_impact: "Medium",
      cx_impact: "Medium",
      dismissed: false,
      feasibility_level: "Easy",
      order: 1,
      priority_level: "${priorityLevel}",
      project_id: "${projectId}",
      recommendation: "${description}",
      title: "${title}",
      type: "${recommendationType}"
      }) {
        returning {
          accepted
          business_impact
          created
          cx_impact
          dismissed
          feasibility_level
          id
          order
          priority_level
          project_id
          recommendation
          title
          type
          updated
        }
      }
  }
`;

const insertRecommendationMacroTheme = ({
  recommendationId,
  macroThemeId,
}: WTDMacroThemeParams) => `
  mutation MyMutation {
    insert_recommendation_macro_theme(objects: {macro_theme_id: ${macroThemeId}, recommendation_id: ${recommendationId}}) {
      returning {
        macro_theme_id
        recommendation_id
      }
    }
  }
`;

/**
 * Sample payload
 * {"objects": [
 *  {"recommendation_id": 1, "sub_theme_id": 87},
 *  {"recommendation_id": 1, "sub_theme_id": 88}
 * ]}
 */

const insertRecommendationSubTheme = () => `
  mutation MyMutation($objects: [recommendation_sub_theme_insert_input!] = {}) {
    insert_recommendation_sub_theme(objects: $objects) {
      affected_rows
    }
  }
`;

const insertStrategicImperative = () => `
  mutation MyMutation($objects: [strategic_imperative_insert_input!] = {}) {
    insert_strategic_imperative(on_conflict: {constraint: strategic_imperative_pkey, update_columns: updated}, objects: $objects) {
      returning {
        created
        description
        id
        project_id
        updated
        user_id_created
        user_id_updated
        order
      }
    }
  }
`;

const getStrategicImperativesByProjectId = ({ projectId }: ProjectParams) => `
  query MyQuery {
    strategic_imperative(where: {project_id: {_eq: "${projectId}"}}) {
      created
      order
      description
      id
      project_id
      updated
      user_id_created
      user_id_updated
    }
  }
`;

const updateStrategicImperatives = () => `
  mutation MyMutation($_set: strategic_imperative_set_input = {}, $_eq: Int = 10) {
    update_strategic_imperative(where: {id: {_eq: $_eq}}, _set: $_set) {
      returning {
        created
        description
        id
        project_id
        updated
        user_id_created
        user_id_updated
        order
      }
    }
  }
`;

const deleteStrategicImperativesById = (id: number) => `
  mutation MyMutation {
    delete_strategic_imperative(where: {id: {_eq: ${id}}}) {
      affected_rows
    }
  }
`;

const deleteStrategicImperativesByProjectId = ({
  projectId,
}: ProjectParams) => `
  mutation MyMutation {
    delete_strategic_imperative(where: {project_id: {_eq: "${projectId}"}}) {
      affected_rows
    }
  }
`;

const getAllBrandByProjectId = ({ projectId }: ProjectParams) => `
  query GetAllBrandsByProjectId {
    project_brand(where: {project_id: {_eq: "${projectId}"}}) {
      project_main_brand
      brand {
        name
        id
        brand_slangs {
          id
          slang
        }
      }
    }
  }
`;

const updateTerms = ({
  id,
  agreedToTerms,
}: {
  id: number;
  agreedToTerms: boolean;
}) => `
  mutation UpdateTermsMutation{
    update_user(where: {id: {_eq: "${id}"}}, _set: {agreed_to_terms: ${agreedToTerms}}) {
      affected_rows
    }
  }
`;

const getAllBrand = () => `
  query MyQuery {
    brand {
      id
      name
      brand_slangs {
        slang
        id
        brand_id
      }
    }
  }
`;

const createBrand = (name: string) => `
  mutation CreateBrandMutation {
    insert_brand(objects: {name: "${name}"}) {
      returning {
        id
        name
      }
    }
}
`;

const createBrandSlang = ({ brandId, slang }: BrandSlangParams) => `
  mutation CreateBrandSlangMutation {
    insert_brand_slang(objects: {brand_id: ${brandId} slang: "${slang}"}) {
      returning {
        id
        slang
        brand_id
      }
    }
  }
`;

const updateBrand = ({ brandId, name }: BrandParams) => `
  mutation UpdateBrandMutation {
    update_brand(where: {id: {_eq: ${brandId}}}, _set: {name: "${name}"}) {
      affected_rows
    }
  }
`;

const deleteSlangFromBrand = (slangId: number) => `
  mutation DeleteSlangFromBrandMutation {
    delete_brand_slang(where: {id: {_eq: ${slangId}}}) {
      affected_rows
    }
  }
`;

const assignBrandToProject = (brandId, projectId, mainBrand) => `
  mutation m {
    insert_project_brand(objects: {brand_id: ${brandId}, project_id: "${projectId}", project_main_brand: ${mainBrand}}) {
      affected_rows
    }
  }
`;

const deleteBrandFromProject = (brandId, projectId) => `
  mutation m {
    delete_project_brand(where: {brand_id: {_eq: ${brandId}}, project_id: {_eq: "${projectId}"}}) {
      affected_rows
    }
  }
`;

const hasuraQueries: HasuraQueries = {
  // Project Settings
  getProjectSettings,
  insertProjectSettings,
  updateProjectSettings,

  // Projects
  getProjectList,
  softDeleteProject,

  getProjects,
  getProducts,
  getProjectFields,
  createProject,
  createClient,
  createClientAccount,
  createTa,
  createTaAccount,
  getVerbatims,

  // Themes
  getThemes,
  updateSubTheme,

  getRecommendation,
  getRecommendations,
  acceptRecommendation,
  dismissRecommendation,

  // User Settings
  getUserSettings,
  createUpdateUserSettings,
  deleteUserSettings,

  // Dashboard Widget
  addDashboardWidget,
  updateDashboardWidget,
  getAllDashboardWidget,

  // Dashboard Widget Recommendations
  getActiveDashboardWidgetRecommendation,
  getAllDashboardWidgetRecommendation,
  addDashboardWidgetRecommendation,
  deleteDashboardWidgetRecommendation,
  activateWidgetRecommendation,
  deactivateWidgetRecommendation,

  // Static Data
  getKPIs,
  getStrategicImperatives,

  // Stakeholder
  getProjectStakeholders,
  getRecommendationStakeholders,

  // JourneySteps
  getProjectJourneySteps,
  getRecommendationJourneySteps,
  updateProjectJourneyStep,

  // Departments
  getDepartments,

  // Recommendations
  insertRecommendation,
  insertRecommendationMacroTheme,
  insertRecommendationSubTheme,

  // Strategic Imperatives
  insertStrategicImperative,
  getStrategicImperativesByProjectId,
  updateStrategicImperatives,
  deleteStrategicImperativesById,
  deleteStrategicImperativesByProjectId,

  // Competitive Set
  getAllBrandByProjectId,

  // Terms
  updateTerms,

  // Brand
  getAllBrand,
  createBrand,
  createBrandSlang,
  updateBrand,
  deleteSlangFromBrand,
  assignBrandToProject,
  deleteBrandFromProject,
};

export { hasuraQueries, HasuraQueries };
