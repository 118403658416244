const ENVIROMENTS = {
  PRODUCTION: 'PRODUCTION',
  DEV: 'DEV',
  STAGING: 'STAGING',
};

function getEnv() {
  if (
    window.location.hostname === 'localhost' ||
    window.location.hostname.includes('dev')
  ) {
    return ENVIROMENTS.DEV;
  }
  if (
    window.location.hostname.includes('staging') ||
    window.location.hostname.includes('netlify.app')
  ) {
    return ENVIROMENTS.STAGING;
  }
  return ENVIROMENTS.PRODUCTION;
}

const CURRENT_ENV = {
  ENVIRONMENT: getEnv(),
};

export { CURRENT_ENV, ENVIROMENTS };
