import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { filterArray, snakeCaseToTitleCase } from '../../utils';

interface ProjectInfoState {
  projectInfoLoading: boolean;
  stakeholders: Array<any> | null;
  stakeholdersDropdown: Array<any> | null;
  stepOfJourney: Array<any> | null;
  stepOfJourneyDropdown: Array<any> | null;
  brands: Array<any> | null;
  brandsDropdown: Array<any> | null;
}

// Define the initial state using that type
const initialState: ProjectInfoState = {
  projectInfoLoading: false,
  stakeholders: [],
  stakeholdersDropdown: [],
  stepOfJourney: [],
  stepOfJourneyDropdown: [],
  brands: [],
  brandsDropdown: [],
};

const projectInfoSlice = createSlice({
  name: 'projectInfo',
  initialState,
  reducers: {
    setProjectInfoLoading(state, action: PayloadAction<any>) {
      state.projectInfoLoading = action.payload;
    },
    setStakeholders: (state, action: PayloadAction<any>) => {
      const stakeholders = filterArray(action.payload);

      state.stakeholders = stakeholders;
      state.stakeholdersDropdown = stakeholders.map((stakeholder: any) => ({
        label: stakeholder,
        value: stakeholder,
      }));
    },
    setStepOfJourney: (state, action: PayloadAction<any>) => {
      const stepOfJourney = filterArray(action.payload);

      state.stepOfJourney = stepOfJourney;
      state.stepOfJourneyDropdown = stepOfJourney.map((step: any) => ({
        label: snakeCaseToTitleCase(step),
        value: step,
      }));
    },
    setBrands: (state, action: PayloadAction<any>) => {
      const brands = filterArray(action.payload);

      state.brands = brands;
      state.brandsDropdown = Object.entries(filterArray(brands) || []).map(
        ([key, value]: [string, any]) => {
          return {
            value: key,
            label: value?.brand_name || 'Unknown',
          };
        }
      );
    },
  },
});

const { setProjectInfoLoading, setStakeholders, setStepOfJourney, setBrands } =
  projectInfoSlice.actions;
const projectInfoReducer = projectInfoSlice.reducer;

export {
  ProjectInfoState,
  projectInfoReducer,
  setProjectInfoLoading,
  setStakeholders,
  setStepOfJourney,
  setBrands,
};
