import { css } from 'lit';

const themeStyles = [css`
  :host {
    --nn-primary-background-color: #141728;
    --nn-body-text-color: #f8f8f8;
    --lumo-primary-text-color: #f8f8f8;
}
    color-scheme: dark;
`];

export {
  themeStyles,
}
