import { delay } from '../../utils';

const getToken = async () => {
  const { store } = await import('../../state/store');

  return store.getState().token.value?.accessToken;
};

async function getAccessToken() {
  const token = await getToken();

  if (token != null) {
    return token;
  }

  await delay(500);

  return getAccessToken();
}

export { getAccessToken, getToken };
