import { BaseQueryFn, createApi } from '@reduxjs/toolkit/query';
import { hasura, hasuraQueries } from '../../modules/hasura';
import { ProjectParams } from '../../modules/hasura/types';

const hasuraBaseQuery = (): BaseQueryFn<any> => async hasuraQuery => {
  const response = await hasura.query(hasuraQuery);
  return { data: response };
};

const apiService = createApi({
  baseQuery: hasuraBaseQuery(),
  endpoints: build => ({
    getProject: build.query<any, any>({
      // note: an optional `queryFn` may be used in place of `query`
      query: (params: ProjectParams) => hasuraQueries.getProjects(params),
      transformResponse: (response: any) => response?.project?.[0],
    }),
    getProjectList: build.query<any, any>({
      // note: an optional `queryFn` may be used in place of `query`
      query: () => hasuraQueries.getProjectList,
      transformResponse: (response: any) => response,
    }),
    getUserSettings: build.query<any, any>({
      // note: an optional `queryFn` may be used in place of `query`
      query: () => hasuraQueries.getUserSettings(),
      transformResponse: (response: any) => response,
    }),
  }),
});

export { apiService };
