import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

interface VerbatimFilter {
  id: number;
  column: string;
  condition: string;
  operator: string;
  possibleOperators: Array<string>;
  possibleValues: Array<string>;
  possibleValuesAreLoading: boolean;
  value: string;
}

// Define a type for the slice state
interface VerbatimFilterState {
  current: Array<VerbatimFilter> | null;
  previous: Array<VerbatimFilter> | null;
}

// Define the initial state using that type
const initialState: VerbatimFilterState = {
  current: null,
  previous: null,
};

const verbatimSlice = createSlice({
  name: 'verbatim',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setCurrentVerbatimFilter: (
      state,
      action: PayloadAction<Array<VerbatimFilter>>
    ) => {
      state.current = action.payload;
    },
    setPreviousVerbatimFilter: (
      state,
      action: PayloadAction<Array<VerbatimFilter>>
    ) => {
      state.current = action.payload;
    },
  },
});

const { setCurrentVerbatimFilter, setPreviousVerbatimFilter } =
  verbatimSlice.actions;
const verbatimReducer = verbatimSlice.reducer;

export {
  VerbatimFilterState as UserSettingsState,
  verbatimReducer,
  setCurrentVerbatimFilter,
  setPreviousVerbatimFilter,
};
