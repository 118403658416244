import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

interface Account {
  id: number;
  name: string;
  agreedToTerms: boolean;
  allowedAgents: Array<any>;
  allowedDocuments: Array<any>;
  allowedModels: Array<any>;
  allowedSources: Array<any>;
  canUpload: boolean;
  clients: Array<any>;
  defaultSource: number;
  isAdmin: boolean;
  products: Array<any>;
  projects: Array<{
    id: number;
    name: string;
    created: string;
    image?: string;
  }>;
  ta: Array<any>;
  tokens: {
    chatToken: string;
    hasuraToken: string;
    searchToken: string;
    cubeToken: string;
  };
  userId: number;
  vanilla: boolean;
  suitableForProjectCreation: boolean;
}

// Define a type for the slice state
interface AccountState {
  accounts: Array<Account>;
  currentAccount: Account | null;
}

// Define the initial state using that type
const initialState: AccountState = {
  accounts: [],
  currentAccount: null,
};

const accountConfigSlice = createSlice({
  name: 'accounts',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setAccounts: (state, action: PayloadAction<Array<Account>>) => {
      state.accounts = action.payload;
    },
    setCurrentAccount: (state, action: PayloadAction<any>) => {
      state.currentAccount = {
        ...action.payload,
        agreedToTerms:
          action.payload?.agreedToTerms || action.payload.agreed_to_terms,
        allowedAgents:
          action.payload?.allowedAgents || action.payload.allowed_agents,
        allowedDocuments:
          action.payload?.allowedDocuments || action.payload.allowed_documents,
        allowedModels:
          action.payload?.allowedModels || action.payload.allowed_models,
        allowedSources:
          action.payload?.allowedSources || action.payload.allowed_sources,
        canUpload: action.payload?.canUpload || action.payload.can_upload,
        defaultSource:
          action.payload?.defaultSource || action.payload.default_source,
        isAdmin: action.payload?.isAdmin || action.payload.is_admin,
        userId: action.payload?.userId || action.payload.user_id,
        suitableForProjectCreation:
          action.payload?.suitableForProjectCreation ||
          action.payload.suitable_for_project_creation,
      };
    },
  },
});

const { setAccounts, setCurrentAccount } = accountConfigSlice.actions;
const accountsReducer = accountConfigSlice.reducer;

export {
  AccountState,
  type Account,
  accountsReducer,
  setAccounts,
  setCurrentAccount,
};
