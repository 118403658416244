import { axios, Axios } from '@bundled-es-modules/axios';
import { getAccessToken } from './utils';
import { AUTH_FUNCTIONS_ENDPOINT } from '../../constants';

class AuthFunctionsClient {
  private client: Axios | null = null;

  public accessToken: string | null | undefined = null;

  public initComplete: Promise<void>;

  constructor() {
    this.initComplete = this._setAxiosInstance();
  }

  async getTokens() {
    this.accessToken = await getAccessToken();
  }

  async _setAxiosInstance() {
    // @ts-ignore
    this.accessToken = await getAccessToken();

    this.client = axios.create({
      baseURL: AUTH_FUNCTIONS_ENDPOINT,
      headers: {
        ContentType: 'application/json',
      },
    });

    this.client.interceptors.request.use(
      async config => {
        await this.getTokens();

        // eslint-disable-next-line no-param-reassign
        config.headers.Authorization = `Bearer ${this.accessToken}`;

        return config;
      },
      error => Promise.reject(error)
    );
  }

  getClaims(id) {
    return this.getWithHeaders('/claims', id);
  }

  getClaimsAccounts() {
    return this.get('/claims-accounts');
  }

  getAdminData(id) {
    return this.getWithHeaders('/admin-data', id);
  }

  saveAdminData(payload, id) {
    return this.postWithHeaders('/admin-data', payload, id);
  }

  async getWithHeaders(path, id) {
    await this.initComplete;
    if (this.client == null) {
      throw new Error('Functions client not initialized');
    }
    this.client.defaults.headers.common['selected-account-id'] = id;
    this.client.defaults.headers.common['protocol-version'] = '2';
    const response = await this.client.get(path);
    if (response.data?.data) {
      return response.data?.data;
    }
    return response.data;
  }

  async get(path) {
    await this.initComplete;
    if (this.client == null) {
      throw new Error('Functions client not initialized');
    }
    const response = await this.client.get(path);
    if (response.data?.data) {
      return response.data?.data;
    }
    return response.data;
  }

  async postWithHeaders(path, body, id) {
    await this.initComplete;
    if (this.client == null) {
      throw new Error('Functions client not initialized');
    }
    this.client.defaults.headers.common['selected-account-id'] = id;
    this.client.defaults.headers.common['protocol-version'] = '2';
    const response = await this.client.post(path, body);
    if (response.data?.data) {
      return response.data?.data;
    }
    return response.data;
  }

  async post(path, body) {
    await this.initComplete;
    if (this.client == null) {
      throw new Error('Functions client not initialized');
    }
    const response = await this.client.post(path, body);
    if (response.data?.data) {
      return response.data?.data;
    }
    return response.data;
  }
}

export { AuthFunctionsClient };
