import { getQueryParams } from '@mch/nn-web-viz/dist/packages/router/utils';

import { CURRENT_ENV, ENVIROMENTS } from './env';

let cubeEndpointViaURLDecoded;
const cubeEndpointViaURL = getQueryParams().get('cubeEndpoint');

if (cubeEndpointViaURL) {
  cubeEndpointViaURLDecoded = decodeURIComponent(cubeEndpointViaURL);
}

const CUBE_ENDPOINTS = {
  [ENVIROMENTS.PRODUCTION]:
    cubeEndpointViaURLDecoded ||
    'https://sleepy-maysville.aws-us-east-2.cubecloudapp.dev/cubejs-api/v1',
  [ENVIROMENTS.STAGING]:
    cubeEndpointViaURLDecoded ||
    'https://military-marmoset.aws-us-east-2.cubecloudapp.dev/cubejs-api/v1',
  [ENVIROMENTS.DEV]:
    cubeEndpointViaURLDecoded ||
    'https://military-marmoset.aws-us-east-2.cubecloudapp.dev/cubejs-api/v1',
  // [ENVIROMENTS.DEV]: cubeEndpointViaURLDecoded || 'http://localhost:8080/cubejs-api/v1',
};

const HASURA_ENDPOINTS = {
  [ENVIROMENTS.PRODUCTION]: 'https://hasura-results.northify.net',
  [ENVIROMENTS.STAGING]: 'https://hasura-results-staging.northify.net',
  [ENVIROMENTS.DEV]: 'https://hasura-results-staging.northify.net',
  // [ENVIROMENTS.DEV]: 'http://localhost:8080',
};

const SEARCH_ENDPOINTS = {
  [ENVIROMENTS.PRODUCTION]: 'https://ms-1e4c7dc1546b-5497.nyc.meilisearch.io',
  [ENVIROMENTS.STAGING]: 'https://ms-1e4c7dc1546b-5497.nyc.meilisearch.io',
  [ENVIROMENTS.DEV]: 'https://ms-1e4c7dc1546b-5497.nyc.meilisearch.io',
};

const FUNCTIONS_ENDPOINTS = {
  [ENVIROMENTS.PRODUCTION]:
    'https://northstar-prod-fn-fa-tasks.azurewebsites.net/api',
  [ENVIROMENTS.STAGING]:
    'https://northstar-staging-fn-fa-tasks.azurewebsites.net/api',
  [ENVIROMENTS.DEV]:
    'https://northstar-staging-fn-fa-tasks.azurewebsites.net/api',
};

const AUTH_FUNCTIONS_ENDPOINTS = {
  [ENVIROMENTS.PRODUCTION]:
    'https://northstar-prod-fn-fa-auth.azurewebsites.net/auth',
  [ENVIROMENTS.STAGING]:
    'https://northstar-staging-fn-fa-auth.azurewebsites.net/auth',
  [ENVIROMENTS.DEV]:
    'https://northstar-staging-fn-fa-auth.azurewebsites.net/auth',
  // [ENVIROMENTS.DEV]: 'http://localhost:8000/auth',
};

const CHAT_URLS = {
  [ENVIROMENTS.PRODUCTION]: 'https://chat.northify.net',
  [ENVIROMENTS.STAGING]: 'https://chat-staging.northify.net',
  [ENVIROMENTS.DEV]: 'https://chat-staging.northify.net',
};

const AUTH0_CONFIGS = {
  [ENVIROMENTS.PRODUCTION]: {
    DOMAIN: '90north.us.auth0.com',
    CLIENT_ID: '4dVSyplrAQpU5H8PwrXVWQIctyN5qoW2',
    AUDIENCE: 'https://northstar-prod-fn-fa-auth.azurewebsites.net/auth/',
  },
  [ENVIROMENTS.STAGING]: {
    DOMAIN: '90north-staging.us.auth0.com',
    CLIENT_ID: 'xSjypfk5DNYsNJLP2SDksvJGZbwfP1e9',
    AUDIENCE: 'https://northstar-staging-fn-fa-auth.azurewebsites.net/auth/',
  },
  [ENVIROMENTS.DEV]: {
    // DOMAIN: '90north-dev.us.auth0.com',
    // CLIENT_ID: 'AYGu9QI2MHLVjcFXw5cYiaqFImhDrBE3',
    DOMAIN: '90north-staging.us.auth0.com',
    CLIENT_ID: 'xSjypfk5DNYsNJLP2SDksvJGZbwfP1e9',
    AUDIENCE: 'https://northstar-staging-fn-fa-auth.azurewebsites.net/auth/',
  },
};

// Exports
const CUBE_ENDPOINT = CUBE_ENDPOINTS[CURRENT_ENV.ENVIRONMENT];
const HASURA_ENDPOINT = HASURA_ENDPOINTS[CURRENT_ENV.ENVIRONMENT];
const FUNCTIONS_ENDPOINT = FUNCTIONS_ENDPOINTS[CURRENT_ENV.ENVIRONMENT];
const AUTH_FUNCTIONS_ENDPOINT =
  AUTH_FUNCTIONS_ENDPOINTS[CURRENT_ENV.ENVIRONMENT];
const CHAT_BASE_URL = CHAT_URLS[CURRENT_ENV.ENVIRONMENT];
const SEARCH_ENDPOINT = SEARCH_ENDPOINTS[CURRENT_ENV.ENVIRONMENT];
const AUTH0_CONFIG = AUTH0_CONFIGS[CURRENT_ENV.ENVIRONMENT];

export {
  CUBE_ENDPOINT,
  HASURA_ENDPOINT,
  FUNCTIONS_ENDPOINT,
  AUTH_FUNCTIONS_ENDPOINT,
  CHAT_BASE_URL,
  SEARCH_ENDPOINT,
  AUTH0_CONFIG,
};
