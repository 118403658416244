import { LitElement, css, html } from 'lit';

import '../../../ee-project-creation-form';

class ProjectOverlay extends LitElement {
  static styles = [
    css`
      :host {
        --nn-primary-text-color: white;
        --lumo-body-text-color: white;
        display: block;
        width: var(--widget-base-width, auto);
        min-width: 946px;
        max-width: 946px;
        min-height: 743px;
        height: 743px;
        border-radius: 16px;
        background-color: #0f111e;
        padding: 24px;
        box-sizing: border-box;
      }

      .container {
        width: 100%;
        height: 100%;
      }
    `,
  ];

  _closeOverlay() {
    this.dispatchEvent(new Event('project-created'));
    this.dispatchEvent(new Event('close-overlay', { bubbles: true }));
  }

  render() {
    return html`
      <ee-project-creation-form
        @project-created=${this._closeOverlay}
      ></ee-project-creation-form>
    `;
  }
}

export { ProjectOverlay };
