/* eslint-disable class-methods-use-this */
import { hasura, hasuraQueries } from '../../modules/hasura';
import {
  setProjectList,
  setProjectListLoading,
} from '../../state/slices/projectList';
import { store } from '../../state/store';

export class ProjectsService {
  async getProjectList() {
    store.dispatch(setProjectListLoading(true));
    try {
      const result: any = await hasura.query(hasuraQueries.getProjectList());

      store.dispatch(setProjectList(result?.project));
    } finally {
      store.dispatch(setProjectListLoading(false));
    }
  }
}
