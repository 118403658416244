import { authFunctionsClient } from '../../modules/functions';
import { setCurrentAccount } from '../../state/slices/accounts';
import { store } from '../../state/store';
import { ClaimsProductType } from './types/ClaimsProductType';

const getClaimsAccounts = async () => {
  const result = await authFunctionsClient.getClaimsAccounts();

  return result;
};

const getClaimsById = async id => {
  const result = await authFunctionsClient.getClaims(id);

  return result;
};

const getDefaultAccountFromClaims = async claimsAccounts => {
  // Retrieve accounts products
  const claimsProducts: ClaimsProductType = claimsAccounts.reduce(
    (acc, curr) => {
      const { products } = curr;

      // eslint-disable-next-line no-param-reassign
      acc = {
        ...acc,
        [curr.accounts]: products,
      };

      return acc;
    },
    []
  );

  const accountsWithAccess = Object.entries(claimsProducts).reduce(
    (acc: Array<string>, [key, value]) => {
      if (
        acc.length === 0 &&
        value.find(product => product.name.toUpperCase() === 'HATE AUDIT')
      ) {
        acc.push(key);
      }

      return acc;
    },
    []
  );

  const selectedAccount = sessionStorage.getItem('selectedAccount');
  const accountToSelect = selectedAccount || accountsWithAccess[0];
  const defaultAccount = claimsAccounts.find(
    acc => acc.id.toString() === accountToSelect.toString()
  );

  /**
   * Initial default account
   * Happens when user just logged in
   */
  if (selectedAccount == null) {
    sessionStorage.setItem(
      'selectedAccount',
      defaultAccount.accounts.toString()
    );
  }

  if (defaultAccount != null) {
    store.dispatch(
      setCurrentAccount(
        claimsAccounts.find(
          acc => acc.id.toString() === defaultAccount.id.toString()
        )
      )
    );
  }

  return defaultAccount;
};

export { getClaimsAccounts, getDefaultAccountFromClaims, getClaimsById };
