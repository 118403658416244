import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

// Define a type for the slice state
interface ClaimsState {
  items: Array<ClaimsProductType> | undefined | any;
}

interface ClaimProduct {
  id: number;
  name: string;
}

interface ClaimsProductType {
  [key: string]: Array<ClaimProduct>;
}


// Define the initial state using that type
const initialState: ClaimsState = {
  items: undefined,
};

const claimsSlice = createSlice({
  name: 'claims',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setClaims: (state, action: PayloadAction<ClaimsProductType[]>) => {
      state.items = action.payload;
    },
  },
});

const { setClaims } = claimsSlice.actions;
const claimsReducer = claimsSlice.reducer;

export { ClaimsState, ClaimsProductType, claimsReducer, setClaims };
