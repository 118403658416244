import { css, html, NNBase } from '@mch/nn-web-viz/dist/packages/base/Base';

export class EENoAccess extends NNBase {
  static styles: any = [
    css`
      .container {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100vh;
        color: white;
      }

      .no-access__wrapper {
        text-align: center;
      }
    `,
  ];

  render() {
    return html`
      <div class="container">
        <div class="no-access__wrapper">
          <h1>Sorry, you don't have access to this page</h1>
          <p>
            Please contact your administrator if you believe this is an error.
          </p>
        </div>
      </div>
    `;
  }
}
