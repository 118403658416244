import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { UserSettings } from 'src/types';

// Define a type for the slice state
interface UserSettingsState {
  value: UserSettings | undefined;
}

// Define the initial state using that type
const initialState: UserSettingsState = {
  value: undefined,
};

const userSettingsSlice = createSlice({
  name: 'userSettings',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    setUserSettings: (state, action: PayloadAction<UserSettings>) => {
      state.value = action.payload;
    },
  },
});

const { setUserSettings } = userSettingsSlice.actions;
const userSettingsReducer = userSettingsSlice.reducer;

export { UserSettingsState, userSettingsReducer, setUserSettings };
