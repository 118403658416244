import { property } from 'lit/decorators';
import { LitElement, html, nothing } from 'lit';

import { Notification } from '@vaadin/notification/vaadin-notification';

// MSAL imports
import {
  PublicClientApplication,
  EventType,
  AuthenticationResult,
} from '@azure/msal-browser';
import {
  AzureConfiguration,
  AzureScope,
  azureConfigurations,
} from './constants';

// Components
import '@mch/nn-web-viz/dist/nn-spinner';
import './components/no-access/ee-no-access';
import './components/login-form/ee-login-form';
import './empathy-engine-shell';

import {
  getClaimsAccounts,
  getDefaultAccountFromClaims,
} from './modules/claims';

import { connect, store } from './state/store';
import { setUser } from './state/slices/user';
import { setaccessToken } from './state/slices/token';
import { setAccounts } from './state/slices/accounts';

import { ProjectsService } from './service/projects';

class AuthShell extends connect(store)(LitElement) {
  @property({ type: Object }) user;

  @property({ type: String }) _authToken: string | null = null;

  @property({ type: Boolean }) _loggingIn: boolean = false;

  @property({ type: Boolean }) _noAccess: boolean = false;

  @property({ type: Boolean }) _hideInitialLogin: boolean = false;

  @property({ type: Object }) _authConfig: AzureConfiguration =
    azureConfigurations.config['90north'];

  @property({ type: Object }) _authScope: AzureScope =
    azureConfigurations.scope['90north'];

  private _projectService: ProjectsService;

  protected createRenderRoot(): Element | ShadowRoot {
    return this;
  }

  constructor() {
    super();

    this._catchRedirectResponse();
    this._projectService = new ProjectsService();
  }

  async _getMsAdClient() {
    const msalInstance = new PublicClientApplication(this._authConfig);

    await msalInstance.initialize();

    this._setActiveAccount(msalInstance);

    // Optional - This will update account state if a user signs in from another tab or window
    msalInstance.enableAccountStorageEvents();

    msalInstance.addEventCallback((event: any) => {
      if (event == null) return;

      if (
        event.eventType === EventType.LOGIN_SUCCESS ||
        event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS ||
        event.eventType === EventType.SSO_SILENT_SUCCESS
      ) {
        const account = event.payload?.account;
        msalInstance.setActiveAccount(account);
      }
    });

    return msalInstance;
  }

  // eslint-disable-next-line class-methods-use-this
  _setActiveAccount(msalInstance: PublicClientApplication) {
    // Default to using the first account if no account is active on page load
    if (
      !msalInstance.getActiveAccount() &&
      msalInstance.getAllAccounts().length > 0
    ) {
      // Account selection logic is app dependent. Adjust as needed for different use cases.
      msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
    }
  }

  async _saveLoginInformation(result) {
    if (!result) return;

    const userData = JSON.parse(atob(result?.accessToken.split('.')[1]));

    store.dispatch(
      setUser({
        nickname: `${userData?.given_name.replace(
          /([a-z])([A-Z])/g,
          '$1 $2'
        )} ${userData?.family_name}`,
        picture: null,
      })
    );
    store.dispatch(setaccessToken(result?.accessToken));

    this._authToken = result?.accessToken;

    await this._fetchTokens();

    this._loggingIn = false;
  }

  async _acquireTokenSilently(instance: PublicClientApplication | null = null) {
    this._loggingIn = true;
    const shouldStop = instance == null;
    const msalInstance =
      instance == null ? await this._getMsAdClient() : instance;

    try {
      const result = await msalInstance.acquireTokenSilent(this._authScope);

      this._setActiveAccount(msalInstance);
      this._saveLoginInformation(result);

      return result;
    } catch (error) {
      if (shouldStop) {
        this._loggingIn = false;
      }
      throw new Error('Error acquiring token silently');
    }
  }

  async _catchRedirectResponse(
    instance: PublicClientApplication | null = null
  ) {
    const configIdentified = localStorage.getItem('azureConfigIdentified');

    if (configIdentified) {
      this._authConfig = JSON.parse(localStorage.getItem('authConfig') || '');
      this._authScope = JSON.parse(localStorage.getItem('authScope') || '');
    }

    this._loggingIn = true;
    const msalInstance =
      instance == null ? await this._getMsAdClient() : instance;

    msalInstance
      .handleRedirectPromise()
      .then((result: AuthenticationResult | null) => {
        if (result === null) {
          this._acquireTokenSilently();
        } else {
          this._setActiveAccount(msalInstance);
          this._saveLoginInformation(result);
        }
      });
  }

  async _loginWithMsAd() {
    this._loggingIn = true;
    const instance = await this._getMsAdClient();

    let result;

    try {
      result = await this._acquireTokenSilently(instance);
    } catch (error) {
      this._catchRedirectResponse(instance);

      instance.loginRedirect(this._authScope);
    }

    this._saveLoginInformation(result);
  }

  async _fetchTokens() {
    const resultClaimsAccounts = await getClaimsAccounts();

    if (resultClaimsAccounts.length === 0) {
      store.dispatch(setAccounts([]));

      Notification.show(
        'No account was assigned to this record, please contact your administrator.',
        {
          position: 'bottom-end',
          duration: 5000,
          theme: 'error',
        }
      );
    } else {
      store.dispatch(setAccounts(resultClaimsAccounts));

      const currentAccount = await getDefaultAccountFromClaims(
        resultClaimsAccounts
      );

      if (currentAccount == null) {
        this._noAccess = true;
      }
    }

    this._getProjectList();
  }

  async _getProjectList() {
    await this._projectService.getProjectList();
  }

  async _logoutWithMsAd() {
    localStorage.removeItem('azureConfigIdentified');
    localStorage.removeItem('authConfig');
    localStorage.removeItem('authScope');
    sessionStorage.removeItem('selectedAccount');

    const instance = await this._getMsAdClient();

    instance.logoutPopup().then(() => {
      this._authToken = null;
      this.user = null;
    });
  }

  _login({ detail: { email } }) {
    localStorage.setItem('azureConfigIdentified', 'true');

    const domain = email.split('@')[1]?.toLowerCase();
    const hasDsiCom = domain === 'dsi.com' || domain === 'daiichisankyo.com';

    if (hasDsiCom) {
      this._authConfig = azureConfigurations.config.dsi;
      this._authScope = azureConfigurations.scope.dsi;
    }

    localStorage.setItem('authConfig', JSON.stringify(this._authConfig));
    localStorage.setItem('authScope', JSON.stringify(this._authScope));

    this._loginWithMsAd();
  }

  render() {
    if (this._noAccess) {
      return html`<ee-no-access></ee-no-access>`;
    }

    if (this._loggingIn) {
      return html` <nn-spinner theme="fancy"></nn-spinner>`;
    }

    if (this._authToken == null) {
      return html` <div
          class="container"
          style="display:flex; height: 100%; width: 100%; justify-content: center; align-items: center;"
        >
          <ee-login-form @login=${this._login}></ee-login-form>
        </div>
        >`;
    }

    if (this._authToken != null) {
      return html`
        <empathy-engine-shell
          @login=${this._loginWithMsAd}
          @logout=${this._logoutWithMsAd}
          @refresh-tokens=${this._fetchTokens}
        >
        </empathy-engine-shell>
      `;
    }

    return nothing;
  }
}

export { AuthShell };
