import { css, html, LitElement } from 'lit';
import { state } from 'lit/decorators';

class EEDiagnostics extends LitElement {
  @state()
  private output: string[] = [];

  static styles = [
    css`
      :host {
        color: white;
      }

      .wrapper {
        padding: 2rem;
      }
    `,
  ];

  protected firstUpdated(): void {
    this.runDiagnostics();
  }

  async runDiagnostics() {
    try {
      this.output.push('✅ JavaScript execution: OK');
    } catch (e: any) {
      this.output.push(
        `❌ JavaScript execution blocked: ${e?.message || 'Unknown error'}`
      );
    }

    // Check localStorage
    try {
      localStorage.setItem('testKey', 'testValue');
      const value = localStorage.getItem('testKey');
      this.output.push(`✅ localStorage available: ${value}`);
      localStorage.removeItem('testKey');
    } catch (e: any) {
      this.output.push(
        `❌ localStorage blocked: ${e?.message || 'Unknown error'}`
      );
    }

    // Check sessionStorage
    try {
      sessionStorage.setItem('testKey', 'testValue');
      const value = sessionStorage.getItem('testKey');
      this.output.push(`✅ sessionStorage available: ${value}`);
      sessionStorage.removeItem('testKey');
    } catch (e: any) {
      this.output.push(
        `❌ sessionStorage blocked: ' ${e?.message || 'Unknown error'}`
      );
    }

    // Check cookies
    try {
      document.cookie = 'netskope_test_cookie=1';
      if (document.cookie.includes('netskope_test_cookie')) {
        this.output.push('✅ Cookies available');
      } else {
        this.output.push('❌ Cookies blocked');
      }
    } catch (e: any) {
      this.output.push(
        `❌ Cookie access blocked: ' + ${e?.message || 'Unknown error'}`
      );
    }

    this.output.push(
      '\nIf any ❌ errors appear, please screenshot this page and send it to IT Support.'
    );

    const result = this.shadowRoot?.querySelector('#result');

    if (result) {
      result.textContent = this.output.join('\n');
    }
  }

  render() {
    return html`
      <div class="wrapper">
        <h2>Login Issue Diagnostic</h2>
        <p>
          This page will test if your browser or device is blocking login flow
          components.
        </p>
        <pre id="result">Running tests...</pre>
      </div>
    `;
  }
}

export { EEDiagnostics };
