import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { TIME_OPTION_IDS } from '../../components/filters/src/timeUtils';
import { ALL_FILTER_VALUE } from '../../components/filters/src/utils';

interface FiltersState {
  brand: string;
  stakeholder: string;
  dateRange: Array<string> | string;
}

const initialState: FiltersState = {
  brand: ALL_FILTER_VALUE,
  stakeholder: ALL_FILTER_VALUE,
  dateRange: TIME_OPTION_IDS.ALL_TIME,
};

const filtersSlice = createSlice({
  name: 'filters',
  initialState,
  reducers: {
    setSelectedBrandFilter: (state, action: PayloadAction<string>) => {
      state.brand = action.payload;
    },
    setSelectedStakeholderFilter: (state, action: PayloadAction<string>) => {
      state.stakeholder = action.payload;
    },
    setSelectedDateRange: (
      state,
      action: PayloadAction<Array<string> | string>
    ) => {
      state.dateRange = action.payload;
    },
  },
});

const {
  setSelectedBrandFilter,
  setSelectedStakeholderFilter,
  setSelectedDateRange,
} = filtersSlice.actions;
const filtersReducer = filtersSlice.reducer;

export {
  filtersReducer,
  setSelectedBrandFilter,
  setSelectedStakeholderFilter,
  setSelectedDateRange,
  FiltersState,
};
