import { css } from 'lit';

const pink = css`#C44DFF`;
const turquoise = css`#2CE7C2`;
const lightPink = css`#f82c91`;
const gray = css`#DADCDD`;
const lightGray = css`#F0F0F1`;
const darkGray = css`#cecece`;
const darkBlue = css`#003767`;
const lightBlack = css`#1b2026`;
const deepOrange = css`#dd2c00`; // error color
const darkViolet = css`#4C17C8`;
const pinkGradient = css`linear-gradient(310deg, #7928CA 0%, #FF0080 100%)`;
const violetGradient = css`linear-gradient(310deg, #611bff 0%, #4410bc 100%)`;

// Colors
const primary = css`#f8029c`;
const secondary = css`#6c00ff`;
const blue = css`#0dbeff`;
const green = css`#00dfb1`;
const orange = css`#ff2e00`;
const yellow = css`#f9ea00`;
const neutral = css`#5E67AE`;
const violet = css`#a15cff`;

// Colors Gradient
const primaryGradient = css`linear-gradient(90deg, #f8029c 0%, #6c00ff 100%)`;
const secondaryGradient = css`linear-gradient(90deg, #611BFF 0%, #4410BC 100%)`;
const blueGradient = css`linear-gradient(90deg, #1bc2ff 0%, #108ebc 100%)`;
const greenGradient = css`linear-gradient(90deg, #30eac5 0%, #10bc9a 100%)`;
const orangeGradient = css`linear-gradient(90deg, #ff441b 0%, #FF2E00 100%)`;
const yellowGradient = css`linear-gradient(90deg, #EAD830 0%, #BCAB10 100%)`;
const neutralGradient = css`linear-gradient(90deg, #C1C7EB 0%, #878CB4 100%)`;
const darkVioletGradient = css`linear-gradient(90deg, #343A68 0%, #181B30 100%)`;
const redGradient = css`linear-gradient(90deg, #FF441B 0%, #BC3010 100%)`;

// Text Colors
const textPrimary = css`#550035`;
const textSecondary = css`#27005C`;
const textBlue = css`#00435C`;
const textGreen = css`#006550`;
const textOrange = css`#5C1100`;
const textYellow = css`#565100`;
const textNeutral = css`#5059A0`;
const textViolet = css`#000000`;
const white = css`#FFFFFF`;

const defaultBorderColor = css`#3E467D`;

const loveColor = css`#14FFCF`;
const hateColor = css`#FF2E00`;
const neutralColor = white;
const defaultBackgroundColor = css`#111322`;

const loveToHateGradientColorSteps = [
  'linear-gradient(90deg, #3de5bd 0%, #2bbca0 100%)',
  'linear-gradient(90deg, #2ad3ae 0%, #1b9e85 100%)',
  'linear-gradient(90deg, #3de5bd 0%, #2bbca0 100%)',
  'linear-gradient(90deg, #2ad3ae 0%, #1b9e85 100%)',
  'linear-gradient(90deg, #8c4ff2 0%, #6142bd 100%)',
  'linear-gradient(90deg, #7a39eb 0%, #5730b3 100%)',
  'linear-gradient(90deg, #fd5ba3 0%, #d63d7f 100%)',
  'linear-gradient(90deg, #fb4091 0%, #c32b6f 100%)',
  'linear-gradient(90deg, #fd5ba3 0%, #d63d7f 100%)',
  'linear-gradient(90deg, #fb4091 0%, #c32b6f 100%)',
];

const loveToHateColorSteps = [
  '#3de5bd',
  '#2ad3ae',
  '#3de5bd',
  '#2ad3ae',
  '#8c4ff2',
  '#7a39eb',
  '#fd5ba3',
  '#fb4091',
  '#fd5ba3',
  '#fb4091',
];

export {
  gray,
  pink,
  darkBlue,
  lightGray,
  lightPink,
  lightBlack,
  darkGray,
  darkViolet,
  pinkGradient,
  violetGradient,
  redGradient,
  deepOrange,
  turquoise,
  // Colors
  primary,
  secondary,
  blue,
  green,
  orange,
  yellow,
  neutral,
  violet,
  // Colors Gradient
  primaryGradient,
  secondaryGradient,
  blueGradient,
  greenGradient,
  orangeGradient,
  yellowGradient,
  neutralGradient,
  darkVioletGradient,
  // Text Colors
  textPrimary,
  textSecondary,
  textBlue,
  textGreen,
  textOrange,
  textYellow,
  textNeutral,
  textViolet,
  white,
  defaultBorderColor,
  // Emotion Colors
  loveColor,
  hateColor,
  neutralColor,
  defaultBackgroundColor,
  loveToHateColorSteps,
  loveToHateGradientColorSteps,
};
