const ALL_FILTER_VALUE = 'all';
const ALL_FILTER_LABEL = 'All';

function addAllValueToFilterArray(filterArray) {
  return [{ label: ALL_FILTER_LABEL, value: ALL_FILTER_VALUE }, ...filterArray];
}

function camelToSpaceCase(s: string): string {
  return s
    .replace(/([a-z])([A-Z])/g, '$1 $2') // Insert space before uppercase letters that follow lowercase letters
    .replace(/^./, char => char.toUpperCase()); // Capitalize the first letter
}

export {
  ALL_FILTER_VALUE,
  ALL_FILTER_LABEL,
  addAllValueToFilterArray,
  camelToSpaceCase,
};
