import { LitElement, css } from 'lit';
import { property } from 'lit/decorators.js';
import { EEProjectCreationFormStyles } from './EEProjectCreationFormStyles';
import { html, nothing } from '@mch/nn-web-viz/dist/packages/base/Base';
import { montserratFont } from '../../assets/styles';
import { hasura, hasuraQueries } from '../../modules/hasura';
import { v4 as uuidv4 } from 'uuid';

import '@vaadin/multi-select-combo-box';
import { vaadinStyles } from '../../assets/styles/vaadin';
import { registerStyles } from '@vaadin/vaadin-themable-mixin';
import { connect, store } from '../../state/store';
import { addProject } from '../../state/slices/projectList';
import { AppConfigState } from '../../state/slices/appConfig';

interface User {
  id: number | string;
  name: string;
}

interface Stakeholder {
  value: string;
  label: string;
}

interface Field {
  client: any;
  product: any;
  ta: any;
  account: any;
}

const OTHER = {
  value: 'other',
  label: 'Other',
};

if (vaadinStyles.register) {
  vaadinStyles.register();
}

registerStyles(
  'vaadin-multi-select-combo-box-chip',
  css`
    [part='remove-button'],
    [part='label'] {
      color: var(--nn-primary-text-color);
    }
  `
);

class EEProjectCreationForm extends connect(store)(LitElement) {
  @property({ type: Object }) _fields: Field | undefined;
  @property({ type: String }) _accountId: string = '';
  @property({ type: String }) _productId: string = '';
  @property({ type: String }) _projectName: string = '';
  @property({ type: String }) _clientId: string = '';
  @property({ type: String }) _newClient: string = '';
  @property({ type: String }) _newClientId: string = '';
  @property({ type: String }) _taId: string = '';
  @property({ type: String }) _newTa: string = '';
  @property({ type: String }) _newTaId: string = '';
  @property({ type: String }) _brand: string = '';
  @property({ type: String }) _projectGoal: string = '';
  @property({ type: Array }) _stakeholders: Array<Stakeholder> | null = [];
  @property({ type: Object }) _currentAccount: User | null = null;

  static styles = [
    ...EEProjectCreationFormStyles,
    ...vaadinStyles,
    montserratFont,
  ];

  stateChanged(_state: { appConfig: AppConfigState }): void {
    this._currentAccount = _state.appConfig.currentAccount;
  }

  async firstUpdated(changedProps) {
    super.firstUpdated(changedProps);

    this._accountId = store.getState().appConfig.value?.accounts || '';

    this._loadProjectFields();
  }

  async _loadProjectFields() {
    const result: any = await hasura.query(hasuraQueries.getProjectFields());

    this._fields = {
      product: this._formatFields(result.product),
      ta: [...this._formatFields(result.ta), OTHER],
      client: [...this._formatFields(result.client), OTHER],
      account: this._formatFields(result.account),
    };
  }

  _formatFields(rawFields) {
    return rawFields.map(item => ({
      value: item.id.toString(),
      label: item.name,
    }));
  }

  async _firstLineBlur() {
    const projectNameField = this.shadowRoot?.getElementById(
      'projectName'
    ) as HTMLInputElement;

    this._projectName = projectNameField?.value.trim() || '';

    if (this._projectName !== '') {
      this.style.setProperty('--second-line-display', 'block');
      setTimeout(() => {
        this.shadowRoot?.getElementById('secondLine')?.classList.add('show');
      }, 100);
    } else {
      this._hideSecondLine();
      this._hideThirdLine();
      this._hideFormCompletion();
    }
  }

  _hideSecondLine() {
    this.style.setProperty('--second-line-display', 'none');
    setTimeout(() => {
      this.shadowRoot?.getElementById('secondLine')?.classList.remove('show');
    }, 100);
  }

  _secondLineBlur() {
    const platformField = this.shadowRoot?.getElementById(
      'platform'
    ) as HTMLInputElement;
    const drugNameField = this.shadowRoot?.getElementById(
      'brand'
    ) as HTMLInputElement;
    const clientNameField = this.shadowRoot?.getElementById(
      'client'
    ) as HTMLInputElement;
    const newClientNameField = this.shadowRoot?.getElementById(
      'newClient'
    ) as HTMLInputElement;

    this._productId = platformField?.value || '';
    this._brand = drugNameField?.value || '';
    this._clientId = clientNameField?.value || '';
    this._newClient = newClientNameField?.value || '';

    if (this._productId !== '' && this._brand !== '' && this._clientId !== '') {
      if (this._clientId === 'other' && this._newClient?.trim() === '') {
        this._hideThirdLine();
      } else {
        this.style.setProperty('--third-line-display', 'block');
        setTimeout(() => {
          this.shadowRoot?.getElementById('thirdLine')?.classList.add('show');
        }, 100);
      }
    } else {
      this._hideThirdLine();
    }
  }

  _hideThirdLine() {
    this.style.setProperty('--third-line-display', 'none');
    setTimeout(() => {
      this.shadowRoot?.getElementById('thirdLine')?.classList.remove('show');
    }, 100);
  }

  _thirdLineBlur() {
    const taField = this.shadowRoot?.getElementById('ta') as HTMLInputElement;
    const newTaField = this.shadowRoot?.getElementById(
      'newTa'
    ) as HTMLInputElement;
    const projectGoalField = this.shadowRoot?.getElementById(
      'projectGoal'
    ) as HTMLInputElement;

    this._taId = taField?.value || '';
    this._projectGoal = projectGoalField?.value || '';
    this._newTa = newTaField?.value || '';

    if (this._taId !== '' && this._projectGoal !== '') {
      if (this._taId === 'other' && this._newTa?.trim() === '') {
        this._hideFormCompletion();
      } else {
        this.style.setProperty('--form-indicator-display', 'none');
        setTimeout(() => {
          this.shadowRoot?.getElementById('submitBtn')?.classList.add('show');
        }, 100);
      }
    } else {
      this._hideFormCompletion();
    }
  }

  _hideFormCompletion() {
    this.style.setProperty('--form-indicator-display', 'block');
    setTimeout(() => {
      this.shadowRoot?.getElementById('submitBtn')?.classList.remove('show');
    }, 100);
  }

  _adjustInputWidth(id) {
    const input = this.shadowRoot?.getElementById(id) as HTMLInputElement;
    const tempSpan = document.createElement('span');

    tempSpan.style.display = 'inline-block';
    tempSpan.style.visibility = 'hidden';
    tempSpan.style.whiteSpace = 'nowrap'; // Prevent wrapping
    tempSpan.innerText = input?.value.trim() || input?.placeholder.trim();
    document.body.appendChild(tempSpan);
    input.style.width = `${tempSpan.offsetWidth + 100}px`; // Add extra width for padding and variations
    document.body.removeChild(tempSpan);
  }

  _adjustSelectWidth(id) {
    const select = this.shadowRoot?.getElementById(id) as HTMLSelectElement;
    const selectedText = select.options[select.selectedIndex].text;
    const tempSpan = document.createElement('span');

    document.body.appendChild(tempSpan);
    select.style.width = `${selectedText.length * 8 + 100}px`;
    document.body.removeChild(tempSpan);
  }

  async _processNewClient() {
    const result = await hasura.query(
      hasuraQueries.createClient(this._newClient)
    );
    this._newClientId = result.insert_client.returning[0].id;

    await hasura.query(
      hasuraQueries.createClientAccount({
        accountId: this._accountId,
        clientId: this._newClientId,
      })
    );
  }

  _resetFields() {
    const projectNameField = this.shadowRoot?.getElementById(
      'projectName'
    ) as HTMLInputElement;
    const platformField = this.shadowRoot?.getElementById(
      'platform'
    ) as HTMLInputElement;
    const drugNameField = this.shadowRoot?.getElementById(
      'brand'
    ) as HTMLInputElement;
    const clientNameField = this.shadowRoot?.getElementById(
      'client'
    ) as HTMLInputElement;
    const newClientNameField = this.shadowRoot?.getElementById(
      'newClient'
    ) as HTMLInputElement;
    const taField = this.shadowRoot?.getElementById('ta') as HTMLInputElement;
    const newTaField = this.shadowRoot?.getElementById(
      'newTa'
    ) as HTMLInputElement;
    const projectGoalField = this.shadowRoot?.getElementById(
      'projectGoal'
    ) as HTMLInputElement;

    projectNameField.value = '';
    platformField.value = '';
    drugNameField.value = '';
    clientNameField.value = '';
    if (newClientNameField != null) {
      newClientNameField.value = '';
    }
    taField.value = '';
    if (newTaField != null) {
      newTaField.value = '';
    }
    projectGoalField.value = '';

    this._projectName = '';
    this._productId = '';
    this._brand = '';
    this._clientId = '';
    this._newClient = '';
    this._newClientId = '';
    this._taId = '';
    this._newTa = '';
    this._newTaId = '';
    this._projectGoal = '';
    this._stakeholders = null;

    this._hideSecondLine();
    this._hideThirdLine();
    this._hideFormCompletion();
  }

  async _processNewTa() {
    const result = await hasura.query(hasuraQueries.createTa(this._newTa));
    this._newTaId = result.insert_ta.returning[0].id;

    await hasura.query(
      hasuraQueries.createTaAccount({
        accountId: this._accountId,
        taId: this._newTaId,
      })
    );
  }

  async _submitForm() {
    if (this._newClient !== '') {
      await this._processNewClient();
    }

    if (this._newTa !== '') {
      await this._processNewTa();
    }

    const result = await hasura.query(
      hasuraQueries.createProject({
        projectName: `"${this._projectName}"`,
        productId: this._productId,
        taId: this._taId === 'other' ? this._newTaId : this._taId,
        clientId:
          this._clientId === 'other' ? this._newClientId : this._clientId,
        accountId: this._currentAccount?.id.toString() || '',
        id: uuidv4(),
      }),
      {
        project_form_data: {
          brand: this._brand,
        },
      }
    );

    store.dispatch(
      addProject({
        id: result.insert_account_project.returning[0].project_id,
        name: this._projectName,
        created: new Date().toISOString(),
      })
    );

    this.dispatchEvent(new CustomEvent('project-created'));
    this._resetFields();
  }

  _stakeholdersValueChanged(e) {
    this._stakeholders = e.detail.value;
  }

  _renderNewClient() {
    return this._clientId === 'other'
      ? html`
          ${this._renderInputText(
            'newClient',
            'New client name',
            this._secondLineBlur
          )}
        `
      : nothing;
  }

  _renderNewTa() {
    return this._taId === 'other'
      ? html`
          ${this._renderInputText('newTa', 'New TA name', this._thirdLineBlur)}
        `
      : nothing;
  }

  _renderInputText(id, placeholder, blurCallback) {
    return html`
      <input
        type="text"
        id=${id}
        placeholder=${placeholder}
        @blur=${blurCallback}
        @input=${() => this._adjustInputWidth(id)}
        maxlength="50"
      />
    `;
  }

  render() {
    return html`<div class="container">
      <h1>
        Hello, we're excited to kick off a new project called
        ${this._renderInputText(
          'projectName',
          'Project name',
          this._firstLineBlur
        )}
        .
      </h1>
      <p id="secondLine">
        We will use the
        <select
          id="platform"
          @blur=${this._secondLineBlur}
          @change=${() => this._adjustSelectWidth('platform')}
        >
          <option value="" disabled selected>Select platform</option>
          ${this._fields?.product.map(product => {
            return html`<option value=${product.value}>
              ${product.label}
            </option>`;
          })}
        </select>
        platform to understand more about the product
        ${this._renderInputText('brand', 'Drug name', this._secondLineBlur)} for
        our client,

        <select
          id="client"
          @blur=${this._secondLineBlur}
          @change=${() => this._adjustSelectWidth('client')}
        >
          <option value="" disabled selected>Select client</option>
          ${this._fields?.client.map(client => {
            return html`<option value=${client.value}>${client.label}</option>`;
          })}
        </select>
        ${this._renderNewClient()}
      </p>
      <p id="thirdLine">
        In this study, by diving into the conversational data from
        <select
          id="ta"
          @blur=${this._thirdLineBlur}
          @change=${() => this._adjustSelectWidth('ta')}
        >
          <option value="" disabled selected>Select TA</option>
          ${this._fields?.ta.map(ta => {
            return html`<option value=${ta.value}>${ta.label}</option>`;
          })}
        </select>
        ${this._renderNewTa()} , we hope to uncover
        ${this._renderInputText(
          'projectGoal',
          'Project goal',
          this._thirdLineBlur
        )}
        .
      </p>
      <p id="formIndicator">Please complete all fields...</p>
      <button id="submitBtn" @click=${this._submitForm}>
        Let's get started!
      </button>
    </div>`;
  }
}

export { EEProjectCreationForm };
