import { AUTH_FUNCTIONS_ENDPOINT, FUNCTIONS_ENDPOINT } from '../../constants';
import { axios, Axios } from '@bundled-es-modules/axios';
import { getHasuraToken } from '../hasura/client';
import { delay } from '../../utils';
import { ALL_TIME_VALUE } from '../../components/filters/src/timeUtils';
import { store } from '../../state/store';
// import { getCubeToken } from 'src/cube/client';
interface SetThemeSummariesBody {
  hasura_token: string | null;
  sub_theme_id?: number;
  macro_theme_id?: number;
}

interface SetThemeFunctionParams {
  subThemeId?: number;
  macroThemeId?: number;
}

interface SummaryParams {
  projectId: number;
  macroThemeId?: number;
}

interface ProjectLevelParams {
  projectId: string;
}

interface ProjectBrandParams extends ProjectLevelParams {
  brandId?: string;
  stakeholder?: string;
  start: string;
  end: string;
}

interface MacroThemeLevelParams {
  projectId: string;
  macroThemeId: string;
  stakeholder?: string;
  journeyMoment?: string;
  brand?: string;
  startDate?: string;
  endDate?: string;
}

const getToken = async () => {
  const { store } = await import('../../state/store');

  return store.getState().token.value?.accessToken;
};

async function getAccessToken() {
  const token = await getToken();

  if (token != null) {
    return token;
  }

  await delay(500);

  return getAccessToken();
}

class FunctionsClient {
  private client: Axios | null = null;
  public hasuraToken: string | null = null;
  public initComplete: Promise<void>;
  public accessToken: string | null | undefined = null;

  constructor() {
    this.initComplete = this._setAxiosInstance();
  }

  async getTokens() {
    this.hasuraToken = await getHasuraToken();
    this.accessToken = await getAccessToken();
  }

  async _setAxiosInstance() {
    await this.getTokens();

    this.client = axios.create({
      baseURL: FUNCTIONS_ENDPOINT,
      headers: {
        ContentType: 'application/json',
      },
    });

    this.client.interceptors.request.use(
      async config => {
        await this.getTokens();

        config.headers['Authorization'] = `Bearer ${this.accessToken}`;

        return config;
      },
      error => {
        return Promise.reject(error);
      }
    );
  }

  async deleteProject(projectId) {
    return this.post('/delete_project', {
      project_id: projectId,
      hasura_token: this.hasuraToken,
    });
  }

  async setThemeSummaries({
    subThemeId,
    macroThemeId,
  }: SetThemeFunctionParams) {
    const body: SetThemeSummariesBody = {
      hasura_token: this.hasuraToken,
    };

    if (subThemeId != null) {
      body['sub_theme_id'] = subThemeId;
    }

    if (macroThemeId != null) {
      body['macro_theme_id'] = macroThemeId;
    }

    return this.post('/set-theme-summaries', body);
  }

  async queryMtSummary({ projectId }: SummaryParams) {
    return this.post('/query-mt-summary-v2', {
      project_id: projectId,
    });
  }

  async downloadCsv(projectId) {
    return this.get(`/download-data-csv?project_id=${projectId}`);
  }

  async queryLoveHateBowChart({
    projectId,
    macroThemeId,
    stakeholder,
    journeyMoment,
    brand,
    startDate,
    endDate,
  }: MacroThemeLevelParams) {
    const payload: any = {
      project_id: projectId,
      macro_theme_id: macroThemeId,
      stakeholder,
      journey_moment: journeyMoment,
      brand,
      start_date: startDate,
      end_date: endDate,
    };

    if (
      stakeholder == null ||
      stakeholder.toUpperCase() === 'ALL' ||
      stakeholder === ''
    ) {
      delete payload.stakeholder;
    }

    if (
      journeyMoment == null ||
      journeyMoment.toUpperCase() === 'ALL' ||
      journeyMoment === ''
    ) {
      delete payload.journey_moment;
    }

    if (brand == null || brand.toUpperCase() === 'ALL' || brand === '') {
      delete payload.brand;
    }

    return this.post('/query-lh-bow-chart', payload);
  }

  async queryLhTimeSeries({ projectId, macroThemeId }: MacroThemeLevelParams) {
    return this.post('/query-lh-timeseries', {
      project_id: projectId,
      macro_theme_id: macroThemeId,
    });
  }

  async queryBrands({
    projectId,
    macroThemeId,
    stakeholder,
  }: MacroThemeLevelParams) {
    const payload = {
      project_id: projectId,
      macro_theme_id: macroThemeId,
      stakeholder,
    };

    if (stakeholder == null || stakeholder.toUpperCase() === 'ALL') {
      delete payload.stakeholder;
    }

    return this.post('/query-brands', payload);
  }

  async queryJmc({
    projectId,
    brandId,
    stakeholder,
    start,
    end,
  }: ProjectBrandParams) {
    const payload = {
      project_id: projectId,
      brand: brandId,
      stakeholder,
      start_date: start,
      end_date: end,
    };

    if (brandId == null || brandId.toUpperCase() === 'ALL') {
      delete payload.brand;
    }

    if (stakeholder == null || stakeholder.toUpperCase() === 'ALL') {
      delete payload.stakeholder;
    }

    return this.post('/query-jmc', payload);
  }

  async getQueryJsView({
    cubeToken,
    sortBy,
    topN,
    projectId,
    dateRange = ALL_TIME_VALUE,
    stakeholder,
    brandId = 'None',
  }: {
    cubeToken: string;
    sortBy: string;
    topN: number;
    projectId: number;
    dateRange: Array<string> | string;
    stakeholder: string;
    brandId: string;
  }) {
    let payload: any = {
      cube_token: cubeToken,
      sort_by: sortBy,
      top_n: topN,
      project_id: projectId,
      brand_id: brandId === 'all' ? 'None' : brandId,
    };

    if (dateRange !== ALL_TIME_VALUE) {
      payload = {
        ...payload,
        date_range: dateRange,
      };
    }

    if (stakeholder !== 'all') {
      payload = {
        ...payload,
        stakeholder_id: stakeholder,
      };
    }

    return this.post('/query-js-view', payload);
  }

  async getQueryProjectThemesView(payload) {
    return this.post('/query-project-themes-view', {
      cube_token: payload.cubeToken,
      project_id: payload.projectId,
    });
  }

  executeMessageOptimizer(behavioralOption, userInput) {
    const body = {
      chatToken: this.hasuraToken,
      behavioral_option: behavioralOption,
      user_input: userInput,
    };

    return this.post('/message-optimizer/generate', body);
  }

  async getQueryThemesSummaryQuestions({ projectId, title }) {
    const hasuraToken = store.getState().appConfig.value?.hasuraToken;

    return this.post('/query-theme-summary-questions', {
      project_id: projectId,
      hasura_token: hasuraToken,
      macro_theme_title: title,
    });
  }

  async get(path) {
    await this.initComplete;
    if (this.client == null) {
      throw new Error('Functions client not initialized');
    }
    const response = await this.client.get(path);
    if (response.data?.data) {
      return response.data?.data;
    }
    return response.data;
  }

  async post(path, body) {
    await this.initComplete;
    if (this.client == null) {
      throw new Error('Functions client not initialized');
    }
    const response = await this.client.post(path, body);
    if (response.data?.data) {
      return response.data?.data;
    }
    return response.data;
  }
}

class AuthFunctionsClient {
  private client: Axios | null = null;
  public accessToken: string | null | undefined = null;
  public initComplete: Promise<void>;

  constructor() {
    this.initComplete = this._setAxiosInstance();
  }

  async getTokens() {
    this.accessToken = await getAccessToken();
  }

  async _setAxiosInstance() {
    // @ts-ignore
    this.accessToken = await getAccessToken();

    this.client = axios.create({
      baseURL: AUTH_FUNCTIONS_ENDPOINT,
      headers: {
        ContentType: 'application/json',
      },
    });

    this.client.interceptors.request.use(
      async config => {
        await this.getTokens();

        config.headers['Authorization'] = `Bearer ${this.accessToken}`;

        return config;
      },
      error => {
        return Promise.reject(error);
      }
    );
  }

  getClaims(id) {
    return this.getWithHeaders('/claims', id);
  }

  getClaimsAccounts() {
    return this.get('/claims-accounts');
  }

  getAdminData(id) {
    return this.getWithHeaders('/admin-data', id);
  }

  saveAdminData(payload, id) {
    return this.postWithHeaders('/admin-data', payload, id);
  }

  async getWithHeaders(path, id) {
    await this.initComplete;
    if (this.client == null) {
      throw new Error('Functions client not initialized');
    }
    this.client.defaults.headers.common['selected-account-id'] = id;
    this.client.defaults.headers.common['protocol-version'] = '2';
    const response = await this.client.get(path);
    if (response.data?.data) {
      return response.data?.data;
    }
    return response.data;
  }

  async get(path) {
    await this.initComplete;
    if (this.client == null) {
      throw new Error('Functions client not initialized');
    }
    const response = await this.client.get(path);
    if (response.data?.data) {
      return response.data?.data;
    }
    return response.data;
  }

  async postWithHeaders(path, body, id) {
    await this.initComplete;
    if (this.client == null) {
      throw new Error('Functions client not initialized');
    }
    this.client.defaults.headers.common['selected-account-id'] = id;
    this.client.defaults.headers.common['protocol-version'] = '2';
    const response = await this.client.post(path, body);
    if (response.data?.data) {
      return response.data?.data;
    }
    return response.data;
  }

  async post(path, body) {
    await this.initComplete;
    if (this.client == null) {
      throw new Error('Functions client not initialized');
    }
    const response = await this.client.post(path, body);
    if (response.data?.data) {
      return response.data?.data;
    }
    return response.data;
  }
}

class TrendscapeFunctionsClient {
  private client: Axios | null = null;
  public accessToken: string | null | undefined = null;
  public cubeToken: string | null | undefined = null;
  public initComplete: Promise<void>;

  constructor() {
    this.initComplete = this._setAxiosInstance();
  }

  async getTokens() {
    this.accessToken = await getAccessToken();
  }

  async _setAxiosInstance() {
    // @ts-ignore
    this.accessToken = await getAccessToken();

    this.client = axios.create({
      baseURL: FUNCTIONS_ENDPOINT,
      headers: {
        Authorization: `Bearer ${this.accessToken}`,
        ContentType: 'application/json',
      },
    });

    this.client.interceptors.request.use(
      async config => {
        await this.getTokens();

        config.headers['Authorization'] = `Bearer ${this.accessToken}`;

        return config;
      },
      error => {
        return Promise.reject(error);
      }
    );
  }

  async queryMacroThemeBubbles(projectId, stakeholder, brandId) {
    await this.initComplete;

    const payload = {
      project_id: projectId,
      stakeholder: stakeholder,
      brand: brandId,
    };

    if (stakeholder === 'all' || stakeholder == null) {
      delete payload.stakeholder;
    }

    if (brandId === 'all' || brandId == null) {
      delete payload.brand;
    }

    return this.post('/query-mt-bubbles', payload);
  }

  async queryProjectInfo(projectId) {
    await this.initComplete;

    return this.post('/query-project-info', {
      project_id: projectId,
    });
  }

  async queryTrendscape(
    id: string,
    startDate?: string,
    endDate?: string,
    journeyMoment?: string
  ) {
    await this.initComplete;

    const payload = {
      project_id: id,
      start_date: startDate,
      end_date: endDate,
      journey_moment: journeyMoment,
    };

    if (journeyMoment == null || journeyMoment.toUpperCase() === 'ALL') {
      delete payload.journey_moment;
    }

    return this.post('/query-trendscape-v2', payload);
  }

  async queryStSide(projectId, subThemeId) {
    await this.initComplete;

    return this.post('/query-st-side', {
      project_id: projectId,
      sub_theme_id: subThemeId,
    });
  }

  async renameSubTheme(projectId, subThemeId, subThemeLabel) {
    await this.initComplete;

    return this.post('rename_sub_theme', {
      project_id: projectId,
      sub_theme_id: subThemeId,
      sub_theme_label: subThemeLabel,
    });
  }

  async get(path) {
    await this.initComplete;
    if (this.client == null) {
      throw new Error('Functions client not initialized');
    }
    const response = await this.client.get(path);
    if (response.data?.data) {
      return response.data?.data;
    }
    return response.data;
  }

  async post(path, body) {
    await this.initComplete;
    if (this.client == null) {
      throw new Error('Functions client not initialized');
    }
    const response = await this.client.post(path, body);
    if (response.data?.data) {
      return response.data?.data;
    }
    return response.data;
  }
}

const functionsClient = new FunctionsClient();
const authFunctionsClient = new AuthFunctionsClient();
const trendMatrixClient = new TrendscapeFunctionsClient();

export {
  functionsClient,
  authFunctionsClient,
  trendMatrixClient,
  AuthFunctionsClient,
  FunctionsClient,
  TrendscapeFunctionsClient,
};
