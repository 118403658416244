import { FunctionsClient } from './functions-client';
import { AuthFunctionsClient } from './auth-functions-client';
import { TrendscapeFunctionsClient } from './trendscape-functions-client';

const functionsClient = new FunctionsClient();
const authFunctionsClient = new AuthFunctionsClient();
const trendMatrixClient = new TrendscapeFunctionsClient();

export {
  functionsClient,
  authFunctionsClient,
  trendMatrixClient,
  AuthFunctionsClient,
  FunctionsClient,
  TrendscapeFunctionsClient,
};
