import { css } from 'lit';

const EmpathyEngineShellStyles = [
  css`
    :host {
      height: 100%;
      display: block;
      background-color: #141728;
      color: var(--nn-body-text-color);
    }
    .page-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      font-size: 2rem;
      color: #fff;
    }
    vaadin-tabs {
      margin: initial;
    }
    .nav-actions {
      display: flex;
      align-items: center;
      width: 100%;
      padding-right: 1rem;
    }
    .search {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-right: 3.5vw;
    }
    .search > nn-button {
      height: 38px;
      font-weight: normal;
      padding: 0 1.5rem;
    }
    .chat nn-icon {
      width: 24px;
      height: 24px;
      fill: #f82c91;
    }
    .chat {
      border-radius: 100%;
      border: 1px solid #fff;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 48px;
      height: 48px;
      cursor: pointer;
      padding-left: 2px;
      margin-right: 24px;
      background-color: rgba(255, 255, 255, 0.1);
      transition: all 0.3s ease;
      box-sizing: border-box;
    }
    .chat:hover {
      background-color: rgba(255, 255, 255, 0.2);
      transform: scale(1.05);
    }
    .chat div {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;
    }
    vaadin-app-layout {
      height: 100%;
      --lumo-primary-text-color: #f8f8f8;
      --lumo-primary-color: #f82c91;
    }
    h3 {
      font-weight: 300;
    }
    .page {
      padding: 1rem;
      height: 100%;
      box-sizing: border-box;
    }
    .navbar-brand__wrapper {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
    .navbar-brand-img {
      max-width: 100%;
      max-height: 4rem;
    }
    .navbar-brand {
      padding: 1.5rem 1rem;
      display: block;
      text-decoration: none;
      color: #fff;
    }
    .app-version {
      font-size: 0.75rem;
    }
    .title {
      font-size: 1rem;
      opacity: 0.5;
    }
    .username {
      color: #fff;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      white-space: normal;
    }
    .userimage {
      width: 48px;
      border-radius: 100%;
    }

    .nav-item {
      display: flex;
      width: 200px;
      height: 44px;
      padding: 8px 12px 8px 8px;
      align-items: center;
      flex-shrink: 0;
      border-radius: 6px;
      background: #181b30;
      margin: 0 auto;
    }
    .nav-icon {
      display: flex;
      padding: 12px;
      align-items: flex-start;
      gap: 8px;
      border-radius: 6px;
      background: var(--primary-600, #cf0082);
    }
    .nav-title {
      color: var(--neutrals-100, #edeef6);
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin-left: 16px;
    }
    .nav-link-href {
      text-decoration: none;
      margin: 0;
    }
    .nav-user {
      display: flex;
      flex-direction: column;
      width: 200px;
      align-items: center;
      position: absolute;
      bottom: 16px;
      gap: 1rem;
    }
    .overlay,
    .create__btn {
      width: 100%;
    }

    .options__wrapper {
      position: relative;
      z-index: 99;
    }
    .options {
      position: absolute;
      width: 70px;
      left: 20px;
      bottom: -10px;
      display: flex;
      flex-direction: column;
      background: #242848;
      padding: 8px;
      border-radius: 8px;
    }
    .options__item {
      cursor: pointer;
      font-size: 16px;
      font-weight: 400;
      line-height: 22px;
      letter-spacing: -0.02em;
      text-align: left;
      padding: 4px 8px;
    }

    .options__item:hover {
      font-weight: 700;
    }
    .new-project {
      display: flex;
      flex-direction: column;
      width: 100%;
      align-items: center;
      position: relative;
      bottom: 0px;
      gap: 1rem;
    }
    .nav-user-details {
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      position: absolute;
      align-items: center;
      bottom: 16px;
      gap: 1rem;
      width: 220px;
    }
    vaadin-side-nav-item > vaadin-icon {
      background: linear-gradient(310deg, #7928ca 0%, #ff0080 100%);
      border-radius: 8px;
      padding: 12px;
      width: 48px;
      height: 48px;
      background-position: center;
      fill: #fff;
      text-align: center;
      box-shadow: 0 0.3125rem 0.625rem 0 rgba(0, 0, 0, 0.12);
      transition: all 0.3s ease;
    }
    vaadin-side-nav-item > .vaadin-icon {
      background: #343a68;
      border-radius: 8px;
      padding: 12px;
      width: 24px;
      height: 24px;
      background-position: center;
      fill: #fff;
      box-shadow: 0 0.3125rem 0.625rem 0 rgba(0, 0, 0, 0.12);
      text-align: center;
      vertical-align: middle;
    }

    vaadin-side-nav-item > .vaadin-icon-submenu {
      padding: 8px;
      width: 24px;
      height: 24px;
      background-position: center;
      fill: #fff;
      text-align: center;
      vertical-align: middle;
    }

    vaadin-side-nav-item[current] > .vaadin-icon {
      background: linear-gradient(310deg, #7928ca 0%, #ff0080 100%);
      border-radius: 8px;
      padding: 12px;
      width: 24px;
      height: 24px;
      background-position: center;
      fill: #fff;
      box-shadow: 0 0.3125rem 0.625rem 0 rgba(0, 0, 0, 0.12);
      text-align: center;
      vertical-align: middle;
    }

    vaadin-side-nav-item > .vaadin-icon > svg {
      width: 24px;
      height: 24px;
      text-align: center;
      vertical-align: middle;
    }
    vaadin-side-nav-item {
      font-size: 16px;
      font-weight: 400;
    }
    vaadin-side-nav-item::part(content) {
      background: #0000;
      border-radius: 10px;
      padding: 4px 8px;
      display: flex;
      align-items: center;
    }

    vaadin-side-nav-item::part(content):hover {
      background: linear-gradient(
        220deg,
        rgba(52, 58, 104, 0.5),
        rgba(20, 23, 40, 0.2)
      );
      transition: all 0.3s ease;
    }
    vaadin-side-nav-item[current]::part(content) {
      background: linear-gradient(
        220deg,
        rgba(52, 58, 104, 0.5),
        rgba(20, 23, 40, 0.2)
      );
      margin: 8px 0;
    }
    /* Select vaadin-side-nav-item elements with slot="children" */
    vaadin-side-nav-item[slot='children'] {
      margin-left: 8px;
      display: flex;
      flex-direction: column;
      gap: 4px;
      font-size: 14px;
    }

    @media (max-width: 600px) {
      vaadin-app-layout {
        --vaadin-app-layout-drawer-overlay: none;
      }
      .side-nav {
        display: block;
        width: 220px; /* Adjust width if needed */
        position: fixed;
        height: 100%;
        z-index: 1000;
        background-color: var(--lumo-base-color);
      }
    }

    vaadin-app-layout[drawer-opened] {
      --vaadin-app-layout-drawer-overlay: none;
    }

    .account-selector__container {
      display: flex;
      padding: 0.5rem 0;
      justify-content: space-between;
      align-items: center;
      flex-wrap: nowrap;
      position: relative;
      margin: 0 auto;
      gap: 0.25rem;
    }

    .account-selector__wrapper {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      height: 100%;
      gap: 8px;
      flex-shrink: 0;
      flex-wrap: wrap;
      position: relative;
      overflow: hidden;
      flex: 1;
    }

    .account-selector__team {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-basis: auto;
      font-size: 16px;
      font-weight: 400;
      color: #ffffff;
      position: relative;
      text-align: left;
      white-space: nowrap;
      z-index: 1;
    }

    .account-selector__client {
      min-width: 0;
      flex-grow: 1;
      flex-basis: auto;
      font-size: 20px;
      font-weight: 900;
      color: #ffffff;
      position: relative;
      text-align: left;
      white-space: nowrap;
      z-index: 2;
    }

    .account-selector__button {
      cursor: pointer;
    }

    .account-options {
      box-sizing: border-box;
      z-index: 2;
      top: -120px;
      position: absolute;
      display: flex;
      width: 250px;
      padding: 24px 18px;
      flex-direction: column;
      align-items: flex-start;
      flex-wrap: nowrap;
      background-color: rgba(28, 31, 40, 0.5);
      border-radius: 22px;
      backdrop-filter: blur(18px);
      box-shadow: 0 24px 30px 0 rgba(28, 31, 40, 0.5);
      margin: 0 0 20px;
    }
  `,
];

export { EmpathyEngineShellStyles };
