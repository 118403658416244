import { property } from 'lit/decorators';
import { html, css, nothing } from 'lit';
import { registerStyles } from '@vaadin/vaadin-themable-mixin/register-styles';
import '@vaadin/app-layout';
import '@vaadin/tabs';
import '@vaadin/select';
import '@vaadin/icon';
import '@vaadin/icons';
import '@vaadin/side-nav';
import '@vaadin/app-layout/vaadin-drawer-toggle';
import { PkRouter, navigate } from '@mch/nn-web-viz/dist/packages/router/index';
import { ellipsisVertical } from '@mch/nn-web-viz/packages/icons/ellipsisVertical';
import '@mch/nn-web-viz/dist/nn-icon';
import '@mch/nn-web-viz/dist/nn-badge';
import '@mch/nn-web-viz/dist/nn-button';
import '@mch/nn-web-viz/dist/nn-overlay';
import '@mch/nn-web-viz/dist/nn-spinner';
import '@mch/nn-web-viz/dist/apps/empathy-engine/nn-ee-avatar';
import { Route } from '@mch/nn-web-viz/dist/packages/router/types';
import { NNBase } from '@mch/nn-web-viz/dist/packages/base/Base';
import {
  endTracking,
  trackAccount,
  trackUser,
} from '@mch/nn-web-viz/dist/packages/analytics';
import { ProjectsService } from './service/projects';
// eslint-disable-next-line import/extensions
import { version } from '../package.json';
import { routePrefix } from './utils';
import { AppConfig, Product } from './types';
import { connect, store } from './state/store';
import { Account, AccountState } from './state/slices/accounts';
import { hasura, hasuraQueries } from './modules/hasura';
import { setUserSettings } from './state/slices/userSettings';
import { getHasuraToken } from './modules/hasura/client';
import './components/ee-project-overlay';
import './components/ee-terms-popup';
import { vaadinStyles } from './assets/styles/vaadin';
import { themeStyles } from './theme';
import { EmpathyEngineShellStyles } from './EmphatyEngineShellStyles';
import { UserState } from './state/slices/user';

if (vaadinStyles.register) {
  vaadinStyles.register();
}

registerStyles(
  'vaadin-app-layout',
  css`
    [part='navbar'],
    [part='drawer'] {
      background-color: #111322;
      border-bottom: 0px;
    }
    [part='drawer'] {
      border-inline-end: 0px solid var(--lumo-contrast-10pct);
      overflow: visible;
    }
  ` as any
);

const getUserSettings = async () => {
  const userSettings = await hasura.query(hasuraQueries.getUserSettings());

  return userSettings;
};

const getPathForExternalProduct = path => {
  const PRODUCT_PATH_MAP = {
    chat: 'https://adele.northify.net/',
  };
  return PRODUCT_PATH_MAP[path];
};

const renderWrapperWithSpinner = (loading, element) => {
  if (loading) {
    return html`<div class="page-wrapper">
      <nn-spinner theme="default"> </nn-spinner>
    </div>`;
  }

  return element;
};

const _endTracking = () => {
  endTracking();
};

class EmpathyEngineShell extends connect(store)(NNBase) {
  @property({ type: String }) _title = 'Projects';

  @property({ type: Object }) _route: Route | undefined;

  @property({ type: String }) _chatToken: string | undefined;

  @property({ type: String, attribute: 'json-string-config' })
  @property({ type: Object })
  config: AppConfig | undefined;

  @property({ type: Object }) user;

  @property({ type: Array }) _products: Product[] | undefined;

  @property({ type: Boolean }) showAvatarMenuOptions = true;

  @property({ type: Boolean }) showAvatarMenuOptionsButton = true;

  @property({ type: Boolean }) showLogoutOption = true;

  @property({ type: Boolean }) _termsAccepted: boolean = false;

  @property({ type: Boolean }) _showAccountOptions: boolean = false;

  @property({ type: String }) _selectedAccount = 'NULL';

  @property({ type: Array }) _accounts: Array<Account> | undefined;

  @property({ type: Object }) _currentAccount: Account | null = null;

  @property({ type: Boolean }) _withAccessToHA: boolean = false;

  @property({ type: Boolean }) _loading: boolean = false;

  @property({ type: Array }) _accountsProducts: Array<any> = [];

  @property({ type: Boolean }) _isAdmin: boolean = false;

  private jsonStringConfig: string | undefined;

  private _projectsService = new ProjectsService();

  static styles = [
    ...themeStyles,
    ...vaadinStyles,
    ...EmpathyEngineShellStyles,
  ];

  declare _router: PkRouter;

  async connectedCallback(): Promise<void> {
    super.connectedCallback();

    getHasuraToken().then(token => {
      this._chatToken = token;
    });
    const data = await getUserSettings();

    if (data?.user_settings.length === 0) {
      await hasura.query(hasuraQueries.createUpdateUserSettings(), {
        objects: { settings: { foo: 'bar' } },
      });

      const userSettings = await getUserSettings();
      store.dispatch(setUserSettings(userSettings.user_settings[0]));
    } else {
      store.dispatch(setUserSettings(data.user_settings[0]));
    }

    this._selectParentCurrentVaadinSideNavItem();

    window.addEventListener('beforeunload', _endTracking);
  }

  disconnectedCallback(): void {
    super.disconnectedCallback();
    window.removeEventListener('beforeunload', _endTracking);
  }

  // eslint-disable-next-line class-methods-use-this
  _getUniqueList(listWithDupe) {
    return listWithDupe.reduce((acc, curr: any) => {
      if (acc.find(a => a.id === curr.id)) {
        return acc;
      }

      return [...acc, curr];
    }, []);
  }

  stateChanged(_state: { accounts: AccountState; user: UserState }): void {
    this.user = _state.user.value;
    this._accounts = _state.accounts.accounts;
    this._products = this._getUniqueList(
      this._accounts.reduce((acc: any, curr) => [...acc, ...curr.products], [])
    );
    this._selectedAccount = _state.accounts.currentAccount?.name || 'NULL';
    this._currentAccount = _state.accounts.currentAccount;
    this._accountsProducts = _state.accounts?.currentAccount?.products || [];
    this._isAdmin = _state.accounts?.currentAccount?.isAdmin || false;

    this._withAccessToHA = this._accountsProducts
      .map(product => product.name.toUpperCase())
      .includes('HATE AUDIT');

    if (!this._termsAccepted) {
      this._termsAccepted =
        _state.accounts?.currentAccount?.agreedToTerms || false;
    }

    // Analytics
    if (_state.accounts?.currentAccount?.userId) {
      trackUser({
        id: _state.accounts.currentAccount.userId,
        name: this.user?.nickname,
      });
    }

    if (_state.accounts.currentAccount != null) {
      trackAccount(_state.accounts.currentAccount);
    }
  }

  constructor() {
    super();

    this.showAvatarMenuOptions = false;

    window.addEventListener('vaadin-router-location-changed', () => {
      this._selectParentCurrentVaadinSideNavItem();
    });

    this._init();
    this.watchScreenSize();
  }

  async _init() {
    this._initRouter();
  }

  _initRouter() {
    const config = {
      routes: [
        {
          path: `${routePrefix}/projects/:id/recommendations/:recommendationId`,
          render: props => html`<ee-theme-recommendations
            .projectId=${props.id}
            .recommendationId=${props.recommendationId}
          ></ee-theme-recommendations>`,
          import: () => import('./ee-theme-recommendations'),
        },
        {
          path: `${routePrefix}/projects/:id/themes/:themeId/wizard`,
          render: props =>
            html`<ee-recommendation-wizard-page
              .projectId=${props.id}
              .macroThemeId=${props.themeId}
            ></ee-recommendation-wizard-page>`,
          import: () => import('./ee-recommendation-wizard-page'),
        },
        {
          path: `${routePrefix}/projects/:id/themes/:themeId`,
          render: props =>
            html`<ee-theme-details
              .projectId=${props.id}
              .macroThemeId=${props.themeId}
            ></ee-theme-details>`,
          import: () => import('./ee-theme-details'),
        },
        {
          path: `${routePrefix}/projects/:id`,
          render: (props, query) => html`<ee-theme-page
            .selectedTabIndex=${query.get('tab')}
            .projectId=${props.id}
          ></ee-theme-page>`,
          import: () => import('./pages/ee-theme-page'),
        },
        {
          path: `${routePrefix}/products`,
          render: () => html`<ee-products-page></ee-products-page>`,
          import: () => import('./ee-products-page'),
        },
        {
          path: `${routePrefix}/message-optimizer`,
          render: () =>
            html`<ee-message-optimizer-page></ee-message-optimizer-page>`,
          import: () => import('./ee-message-optimizer-page'),
        },
        {
          path: `${routePrefix}/projects`,
          render: () => html`<ee-projects-page></ee-projects-page>`,
          import: () => import('./pages/ee-projects-page'),
        },
        {
          path: `${routePrefix}/admin`,
          render: () => html`<ee-admin-page></ee-admin-page>`,
          import: () => import('./pages/ee-admin-page'),
        },
        {
          path: `/debug`,
          render: () => html`<ee-diagnostics></ee-diagnostics>`,
          import: () => import('./pages/ee-diagnostics'),
        },
        {
          path: '*',
          redirect: () => `${routePrefix}/projects`,
        },
      ],
    };
    this._router = new PkRouter(config, newRoute => {
      this._route = newRoute;
    });
  }

  updated(cp) {
    super.updated(cp);
    if (cp.has('jsonStringConfig') && this.jsonStringConfig != null) {
      this.config = JSON.parse(this.jsonStringConfig);
    }
  }

  get _newProjectOverlay() {
    return html`
      <nn-overlay class="overlay">
        ${this.renderCreateProjectButton()}
        <ee-project-overlay
          @project-created=${() => {
            this.dispatchEvent(new CustomEvent('refresh-tokens'));
            // TODO: Add project to projects list in state
          }}
          slot="content"
        ></ee-project-overlay>
      </nn-overlay>
    `;
  }

  _toggleOptions() {
    this.showAvatarMenuOptions = !this.showAvatarMenuOptions;
  }

  _renderLogoutOption() {
    return this.showLogoutOption
      ? html`
          <div
            class="options__item"
            @click=${() => this._logout()}
            @keyup=${() => this._logout()}
          >
            Logout
          </div>
        `
      : nothing;
  }

  // render logout ellipsis
  _renderThreeDots() {
    if (!this.showAvatarMenuOptionsButton) return nothing;

    const icon: any = ellipsisVertical;

    return html`
      <div class="options__wrapper">
        <nn-icon .svg=${icon} @click=${this._toggleOptions}></nn-icon>
        ${this.showAvatarMenuOptions
          ? html`<div class="options">${this._renderLogoutOption()}</div>`
          : nothing}
      </div>
    `;
  }

  async _logout() {
    this.dispatchEvent(new CustomEvent('logout'));
    localStorage.clear();
  }

  async _login() {
    this.dispatchEvent(new CustomEvent('login'));
  }

  _selectParentCurrentVaadinSideNavItem() {
    const currentVaadinSideNavItem = this.shadowRoot?.querySelector(
      'vaadin-side-nav-item[current]'
    );

    if (currentVaadinSideNavItem) {
      const parent = currentVaadinSideNavItem.closest(
        'vaadin-side-nav-item:not([current])'
      );
      if (parent) {
        parent.setAttribute('current', '');
      }
    }
  }

  isAnyChildCurrent() {
    const currentPath = window.location.pathname;
    return (this._products || [])
      .filter(p => !p.suitableForProjectCreation)
      .some(p => `${routePrefix}/${p.path}` === currentPath);
  }

  _acceptedClicked() {
    this._termsAccepted = true;
  }

  _renderTerms() {
    return html`<ee-terms-popup
      visible
      @accepted="${this._acceptedClicked}"
    ></ee-terms-popup>`;
  }

  _renderAccountSelector() {
    return html`
      <vaadin-side-nav-item>
        <div class="account-selector__container">
          <div class="account-selector__wrapper">
            <span class="account-selector__team">Team</span
            ><span class="account-selector__client"
              >${this._selectedAccount}</span
            >
          </div>
          <div
            class="account-selector__button"
            @click="${this._toggleAccountOptions}"
            @keydown="${this._toggleAccountOptions}"
          >
            <svg
              width="24"
              height="25"
              viewBox="0 0 24 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.5 16.5C13.3284 16.5 14 17.1719 14 18C14 18.8281 13.3284 19.5 12.5 19.5C11.6716 19.5 11 18.8281 11 18C11 17.1719 11.6716 16.5 12.5 16.5ZM12.5 11.5C13.3284 11.5 14 12.1719 14 13C14 13.8281 13.3284 14.5 12.5 14.5C11.6716 14.5 11 13.8281 11 13C11 12.1719 11.6716 11.5 12.5 11.5ZM12.5 9.5C11.6716 9.5 11 8.82812 11 8C11 7.17156 11.6716 6.5 12.5 6.5C13.3284 6.5 14 7.17156 14 8C14 8.82812 13.3284 9.5 12.5 9.5Z"
                fill="white"
              />
            </svg>
          </div>
        </div>
      </vaadin-side-nav-item>
    `;
  }

  _toggleAccountOptions() {
    this._showAccountOptions = !this._showAccountOptions;
  }

  _renderAccountOptions() {
    return html`
      <div class="account-options">
        ${(store.getState().accounts.accounts || []).map(
          client => html`
            <div
              style="box-sizing: border-box; width:100%;padding:8px;display:flex;align-items:center;flex-wrap:nowrap;cursor:pointer;${this
                ._selectedAccount === client.name
                ? 'background-color:rgba(255, 255, 255, 0.19);;border-radius:12px;'
                : ''}
                 transition: background-color 0.3s;"
            >
              <span style="font-size:16px;font-weight:700;color:#ffffff;"
                >${client.name}</span
              >
            </div>
          `
        )}
        ${this._isAdmin
          ? html`<div
              @click=${this._manageProjectsClicked}
              @keydown=${this._manageProjectsClicked}
              style="padding:8px;gap:8px;display:flex;align-items:center;flex-wrap:nowrap;cursor:pointer;$
                 transition: background-color 0.3s;"
            >
              <span
                style="font-size:16px;font-weight:700;color:#f8029c;margin-top:1rem;"
                >Manage Project</span
              >
            </div>`
          : nothing}
      </div>
    `;
  }

  _manageProjectsClicked() {
    this._showAccountOptions = false;

    navigate(`${routePrefix}/admin`);
  }

  render() {
    if (!this._termsAccepted) {
      return this._renderTerms();
    }
    return html`
      <vaadin-app-layout theme="dark" primary-section="drawer" drawer-opened>
        <vaadin-tabs orientation="vertical" slot="drawer">
          <a class="navbar-brand" href="/">
            <div class="navbar-brand__wrapper">
              <img
                src="https://media.northify.net/logos/90N.svg"
                class="navbar-brand-img"
                alt="main_logo"
              />
              <span class="app-version">v${version}</span>
            </div>
          </a>
          <!-- Projects section -->
          <vaadin-side-nav style="width:220px;">
            <vaadin-side-nav-item>
              <div class="vaadin-icon" slot="prefix">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="16"
                  width="20"
                  viewBox="0 0 576 512"
                >
                  <path
                    stroke="#ffffff"
                    d="M512 352H160c-17.7 0-32-14.3-32-32V96c0-17.7 14.3-32 32-32H277.5c8.5 0 16.6 3.4 22.6 9.4l26.5 26.5c18 18 42.4 28.1 67.9 28.1H512c17.7 0 32 14.3 32 32V320c0 17.7-14.3 32-32 32zM349.3 77.3L322.7 50.7c-12-12-28.3-18.7-45.3-18.7H160c-35.3 0-64 28.7-64 64V320c0 35.3 28.7 64 64 64H512c35.3 0 64-28.7 64-64V160c0-35.3-28.7-64-64-64H394.5c-17 0-33.3-6.7-45.3-18.7zM32 112c0-8.8-7.2-16-16-16s-16 7.2-16 16V352c0 70.7 57.3 128 128 128H464c8.8 0 16-7.2 16-16s-7.2-16-16-16H128c-53 0-96-43-96-96V112z"
                  />
                </svg>
              </div>
              Projects
              <vaadin-side-nav-item
                slot="children"
                @click=${() => {
                  navigate(`${routePrefix}/projects`);
                }}
              >
                Your Projects
              </vaadin-side-nav-item>
              <vaadin-side-nav-item
                slot="children"
                path="${routePrefix}/archive"
                @click=${() => {
                  navigate(`${routePrefix}/archive`);
                }}
              >
                Archive
              </vaadin-side-nav-item>
              <vaadin-side-nav-item
                slot="children"
                path="${routePrefix}/trash"
                @click=${() => {
                  navigate(`${routePrefix}/trash`);
                }}
              >
                Trash
              </vaadin-side-nav-item>
            </vaadin-side-nav-item>

            <vaadin-side-nav-item ?current=${this.isAnyChildCurrent()}>
              <div class="vaadin-icon" slot="prefix">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="16"
                  width="20"
                  viewBox="0 0 512 512"
                >
                  <path
                    stroke="#ffffff"
                    d="M325.8 152.3c1.3 4.6 5.5 7.7 10.2 7.7s8.9-3.1 10.2-7.7L360 104l48.3-13.8c4.6-1.3 7.7-5.5 7.7-10.2s-3.1-8.9-7.7-10.2L360 56 346.2 7.7C344.9 3.1 340.7 0 336 0s-8.9 3.1-10.2 7.7L312 56 263.7 69.8c-4.6 1.3-7.7 5.5-7.7 10.2s3.1 8.9 7.7 10.2L312 104l13.8 48.3zM115.7 346.2L75.5 307l55.5-8.1c15.6-2.3 29.2-12.1 36.1-26.3l24.8-50.3 24.8 50.3c7 14.2 20.5 24 36.1 26.3l55.5 8.1-40.2 39.2c-11.3 11-16.4 26.9-13.8 42.4l9.5 55.4-49.5-26.1c-14-7.4-30.7-7.4-44.7 0L120 444l9.5-55.4c2.7-15.6-2.5-31.4-13.8-42.4zm54.7-188.8l-46.3 94L20.5 266.5C.9 269.3-7 293.5 7.2 307.4l74.9 73.2L64.5 483.9c-3.4 19.6 17.2 34.6 34.8 25.3l92.6-48.8 92.6 48.8c17.6 9.3 38.2-5.7 34.8-25.3L301.6 380.6l74.9-73.2c14.2-13.9 6.4-38.1-13.3-40.9L259.7 251.4l-46.3-94c-8.8-17.9-34.3-17.9-43.1 0zm258.4 85.8l11 38.6c1 3.6 4.4 6.2 8.2 6.2s7.1-2.5 8.2-6.2l11-38.6 38.6-11c3.6-1 6.2-4.4 6.2-8.2s-2.5-7.1-6.2-8.2l-38.6-11-11-38.6c-1-3.6-4.4-6.2-8.2-6.2s-7.1 2.5-8.2 6.2l-11 38.6-38.6 11c-3.6 1-6.2 4.4-6.2 8.2s2.5 7.1 6.2 8.2l38.6 11z"
                  />
                </svg>
              </div>
              Tools
              ${(this._products || [])
                .filter(p => !p.suitableForProjectCreation)
                .map(
                  p => html`
                    <vaadin-side-nav-item
                      slot="children"
                      path="${routePrefix}/${p.path}"
                      @click=${() => {
                        if (p.is_external_path) {
                          window.open(
                            getPathForExternalProduct(p.path),
                            '_blank'
                          );

                          this._selectParentCurrentVaadinSideNavItem();
                        }
                        navigate(`${routePrefix}/${p.path}`);
                      }}
                    >
                      ${p.name}
                    </vaadin-side-nav-item>
                  `
                )}
            </vaadin-side-nav-item>

            <vaadin-side-nav-item>
              <div class="vaadin-icon" slot="prefix">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="16"
                  width="20"
                  viewBox="0 0 640 512"
                >
                  <path
                    fill="#ffffff"
                    d="M307.2 66.2L47.6 160l74 26.7c10.3-6.9 21.5-12.6 33.4-17.1l159.4-59.8c8.3-3.1 17.5 1.1 20.6 9.4s-1.1 17.5-9.4 20.6L166.2 199.6c-1.5 .5-2.9 1.1-4.3 1.7l145.3 52.5c4.1 1.5 8.4 2.2 12.8 2.2s8.7-.8 12.8-2.2L592.4 160 332.8 66.2c-4.1-1.5-8.4-2.2-12.8-2.2s-8.7 .8-12.8 2.2zM296.3 283.9L126.9 222.7C99.4 246 82.1 279.9 80.2 316.9c5.9 13.2 10.2 27.5 13.4 41.5c6.4 27.6 10.7 65.9 2.1 108.7c-.9 4.3-3.4 8-7.1 10.4s-8.2 3.1-12.4 2l-64-16c-5.2-1.3-9.4-5.1-11.2-10.2s-.9-10.7 2.3-14.9c8.6-11.7 16-24.6 22.5-37.6C37.2 377.8 48 348.4 48 320c0-.6 0-1.2 .1-1.8c1.4-41 18-79.1 45.1-107.7L15.8 182.6C6.3 179.1 0 170.1 0 160s6.3-19.1 15.8-22.6L296.3 36.1c7.6-2.7 15.6-4.1 23.7-4.1s16.1 1.4 23.7 4.1L624.2 137.4c9.5 3.4 15.8 12.5 15.8 22.6s-6.3 19.1-15.8 22.6L343.7 283.9c-7.6 2.7-15.6 4.1-23.7 4.1s-16.1-1.4-23.7-4.1zm-122-10L160.4 406.3c.7 .8 1.8 2.1 3.7 3.7c6 5.2 16.5 11.5 31.9 17.5C226.4 439.4 270.3 448 320 448s93.6-8.6 124.1-20.6c15.4-6 25.8-12.3 31.9-17.5c1.9-1.6 3-2.8 3.7-3.7L465.7 273.8l31-11.2L512 408c0 35.3-86 72-192 72s-192-36.7-192-72l15.3-145.4 31 11.2zM480.5 405a.2 .2 0 1 0 -.3-.1 .2 .2 0 1 0 .3 .1zm-321 0a.1 .1 0 1 0 .2 0 .1 .1 0 1 0 -.2 0zM67 444.2c2.5-20.7 1.7-40-.5-56.7c-3.8 10-8 19.3-12.1 27.6c-3.8 7.6-7.9 15.2-12.5 22.8L67 444.2z"
                  />
                </svg>
              </div>
              Learn
              <vaadin-side-nav-item
                slot="children"
                @click=${() => {
                  window.open(
                    'https://www.northify.net/user-onboarding/ha-create-project.pdf',
                    '_blank'
                  );
                }}
              >
                Create a Project
              </vaadin-side-nav-item>

              <vaadin-side-nav-item
                slot="children"
                @click=${() => {
                  window.open(
                    'https://www.northify.net/user-onboarding/ha-log-in.pdf',
                    '_blank'
                  );
                }}
              >
                Log In
              </vaadin-side-nav-item>

              <vaadin-side-nav-item
                slot="children"
                @click=${() => {
                  window.open(
                    'https://www.northify.net/user-onboarding/ha-download-vis.pdf',
                    '_blank'
                  );
                }}
              >
                Download Visualizations
              </vaadin-side-nav-item>

              <vaadin-side-nav-item
                slot="children"
                @click=${() => {
                  window.open(
                    'https://www.northify.net/user-onboarding/ha-read-verbatims.pdf',
                    '_blank'
                  );
                }}
              >
                Read Verbatims
              </vaadin-side-nav-item>

              <vaadin-side-nav-item
                slot="children"
                @click=${() => {
                  window.open(
                    'https://www.northify.net/user-onboarding/ha-troubleshooting.pdf',
                    '_blank'
                  );
                }}
              >
                Troubleshooting
              </vaadin-side-nav-item>
              <vaadin-side-nav-item
                slot="children"
                @click=${() => {
                  window.open(
                    'https://www.northify.net/security-and-privacy/',
                    '_blank'
                  );
                }}
              >
                Security and Privacy
              </vaadin-side-nav-item>

              <vaadin-side-nav-item
                slot="children"
                @click=${() => {
                  window.open(
                    'https://www.northify.net/terms-and-conditions',
                    '_blank'
                  );
                }}
              >
                Terms and Conditions
              </vaadin-side-nav-item>
            </vaadin-side-nav-item>
          </vaadin-side-nav>

          <div class="nav-user-details">
            <div class="new-project">${this._newProjectOverlay}</div>
            ${this._showAccountOptions ? this._renderAccountOptions() : nothing}

            <vaadin-side-nav style="width:220px;">
              ${this._renderAccountSelector()}
              <vaadin-side-nav-item>
                <div class="" slot="prefix">
                  <img
                    class="userimage"
                    src=${this.user?.picture ||
                    'https://media.northify.net/icons/hate-audit-192x192.png'}
                    alt="user profile"
                  />
                </div>
                <div class="username" ?hidden=${this.screen.isMobile}>
                  <span>
                    ${this.user?.nickname ? this.user.nickname : 'Login'}</span
                  >
                </div>
                <vaadin-side-nav-item
                  slot="children"
                  @click=${() => this._logout()}
                >
                  ${this.user ? 'Logout' : 'Login'}
                </vaadin-side-nav-item>
              </vaadin-side-nav-item>
            </vaadin-side-nav>
          </div>
        </vaadin-tabs>

        <div class="page">
          ${renderWrapperWithSpinner(
            this._loading,
            this._withAccessToHA
              ? html`${this._route?.render &&
                this._route.render(this._route.props, this._route.query)}`
              : html`<div class="page-wrapper">
                  You account does not have access to this application.
                </div>`
          )}
        </div>
      </vaadin-app-layout>
    `;
  }

  renderCreateProjectButton() {
    return html`
      <div slot="invoker">
        ${this._currentAccount?.suitableForProjectCreation
          ? html` <nn-button red class="create__btn">+ NEW PROJECT</nn-button> `
          : nothing}
      </div>
    `;
  }
}

export { EmpathyEngineShell };
